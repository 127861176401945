import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Footer from 'layouts/LayoutFooter'
import MaintenanceNotice from 'layouts/MaintenanceNotice'

const LOGO = '/static/images/STLogoLarge.svg'
const PIRATE_SHIP = '/static/images/pirate-ship.svg'

const Error503 = () => {
    return (
        <div>
            <MaintenanceNotice />
            <Container>
                <Row>
                    <Col xs={12} className="main-nav-container mt-3">
                        <img
                            src={LOGO}
                            alt="StorageTreasures Logo"
                            className="nav-bar-logo cursor-pointer"
                        />
                    </Col>
                    <Col xs={12} className="error-page my-5 d-flex flex-column align-items-center">
                        <img src={PIRATE_SHIP} alt="Pirate Ship" className="error-hero" />
                        <div className="text">
                            <h1 className="mt-3 error-header text-center font-weight-bolder">
                                Maintenance <br/> In Progress
                            </h1>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer showRegisterNow={false} showLinks={false} showCopyRight={false} add_bottom_top />
        </div>
    )
}

export default Error503
