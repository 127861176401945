import React, { ReactNode } from 'react';
import { User } from './interfaces';
import { FACILITY_MANAGER, PRIVATE_SELLER } from 'helpers/users';
import { useGlobalContext } from '../context/GlobalContext';

export const userTypeMap = { 1: 'Facility Manager', 2: 'Auction Buyer' };

interface HelpLinkProps {
    children: ReactNode | ((props: { contact: string, contactHref: string, showSupportEmail: boolean }) => ReactNode)
    prepend?: ReactNode
    append?: ReactNode
    url?: string
    supportEmail?: boolean
    supportEmailPrepend?: string
    text?: string
    showSupportEmail?: boolean
    isLink?: boolean
    user?: User
    [x: string]: any
}

const HelpLink: React.FC<HelpLinkProps> = ({
    children,
    prepend,
    append,
    url = '',
    supportEmail = false,
    supportEmailPrepend = '',
    text = 'Submit a request',
    showSupportEmail,
    isLink = true,
    user: propsUser,
    ...props
}) => {
    const globalContext = useGlobalContext() as { user: User };
    const user = propsUser || globalContext.user;

    let _showSupportEmail = user?.reg_type == FACILITY_MANAGER.id || user?.reg_type == PRIVATE_SELLER.id;

    if (typeof showSupportEmail === 'boolean') _showSupportEmail = showSupportEmail;

    const getUserMembershipInfo = (type: string) => {
        switch (type) {
            case 'ProBidder':
                return {
                    membershipType: 'probidder',
                    membershipPb: 'pb_regular',
                };
            case 'ProBidder Max':
                return {
                    membershipType: 'probidder_max',
                    membershipPb: 'pbm_high',
                };
            case 'ProBusiness':
                return {
                    membershipType: 'probusiness',
                    membershipPb: 'pb_important',
                };
            case 'Basic':
            case 'Basic Bidder':
            default:
                return {
                    membershipType: 'basic_bidder',
                    membershipPb: 'bb_standard',
                };
        }
    };

    const getUserTypeName = (typeName: string) => {
        if (!typeName) return '';
        return typeName.toLowerCase().replace(/\s+/g, '_');
    };

    const membershipType = user?.membership?.type ? getUserMembershipInfo(user.membership.type).membershipType : 'basic_bidder';
    const membershipPb = user?.membership?.type ? getUserMembershipInfo(user.membership.type).membershipPb : 'bb_standard';
    const userTypeName = user?.type_name ? getUserTypeName(user.type_name) : '';

    const params = user?.user_id ? `?tf_anonymous_requester_email=${user?.email}&tf_25303334875163=${membershipType}&tf_25818560241947=${membershipPb}&tf_80640587=${userTypeName}` : '';
    const contact = _showSupportEmail ? 'support@storagetreasures.com' : text;
    const contactHref = _showSupportEmail ? 'mailto:support@storagetreasures.com' : `https://help.storagetreasures.com/hc/en-us${url + params}`;

    if (typeof children === 'function') return children({ contact, contactHref, showSupportEmail: _showSupportEmail });

    const Component = isLink ? 'a' : 'span';

    if (_showSupportEmail) {
        return (
            <>
                <Component
                    href="mailto:support@storagetreasures.com"
                    target="_blank"
                    rel="nofollow noopener"
                    {...props}
                >
                    support@storagetreasures.com
                </Component>
            </>
        );
    }

    return (
        <>
            {prepend}
            <Component
                href={contactHref}
                target="_blank"
                rel="nofollow noopener"
                {...props}
            >
                {children}
            </Component>
            {append}
        </>
    );
};

export default HelpLink;