import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';

const Question = '/static/images/question.svg';

class MissingBlock extends Component {
    render() {
        const {
            id = 'table-missing-results',
            image = Question,
            imageClass = 'standard',
            message = 'Nothing here. Check back soon!',
            show = true
        } = this.props;


        return show && (
            <Row id={id} className="missing-block d-flex justify-content-center align-items-center">
                <div className="flex-column align-items-center mt-4">
                    <img src={image} alt="Empty"
                        className={` ${imageClass} mb-3 missing-image mx-auto d-block`}
                        style={{ minHeight: 91 }}/>

                    <div className="st-h3">{message}</div>
                </div>
            </Row>
        )
    }
}

MissingBlock.defaultProps = {
    id: undefined,
};

export default MissingBlock;
