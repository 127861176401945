import React, {Component} from 'react';
import {connect} from 'react-redux';
import {get, includes} from 'lodash';

import {getCountries, setSelectedState} from 'actions';

import AsyncSelect from 'react-select/async';

class CountriesAndStates extends Component {
    componentDidMount = () => {
        const {
            countries = [],
        } = this.props;

        if (!countries || countries.length < 1) {
            this.props.getCountries();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.getStateName) {
            this.props.getStateName(this.valueLookup(this.props.value))
        }
    }

    filterStates = (inputValue) => {
        let countries = this.getOptions(inputValue);


        // remove empty options
        countries = countries.map((country) => ({ ...country, options: country.options.filter((state) => state !== false) }));

        return countries.filter((country) => get(country, 'options', []).length > 0);
    };

    loadOptions = (inputValue, callback) => {
        callback(this.filterStates(inputValue));
    };

    getOptions = (search) => {
        // Make sure search has a value is lowercased
        search = search
            ? search.toLowerCase()
            : '';

        const {
            countries = [],
        } = this.props;

        return countries.map(({ country_name: label, states = [] }) => {
            const options = states.map((state) => {
                const {
                    state_code: value = '',

                    state_name: label = '',
                } = state;


                if (search.length == 2) {
                    if (value.toLowerCase() == search) {
                        return { label, value };
                    }
                    return false;
                }

                // if a search term is entered then search it
                if (search.length > 0) {
                    if (includes(label.toLowerCase(), search)) {
                        return { label, value };
                    }

                    return false;
                }

                // Search not found show all results
                return { label, value };
            });

            return { label, options };
        });
    };

    getTheme = (theme) => ({
        ...theme,
        borderRadius: 0,

        spacing: {
            ...theme.spacing,
            controlHeight: 50,
        },
    });

    optionSelected = (v) => {
        const { label = '', value = '' } = v || {};

        const {
            name,
            updateMethod,
            handleStateInputChange,
            setSelectedState,
        } = this.props;

        setSelectedState && setSelectedState({ label, value });
        handleStateInputChange && handleStateInputChange(value, label);
        updateMethod && updateMethod({ target: { name, value, label } });
    };

    valueLookup = (state_code) => get(this.filterStates(state_code), '0.options.0.label', '');

    render() {
        const {
            value = '',
            name = 'country',
            do_lookup = false,
            isClearable = true,
            showClearableX = true,
        } = this.props;
        let defaultValue = value ? { label: value, value } : '';

        if (do_lookup && value && typeof value === 'string') {
            // try to swap out the abbreviation for the full name
            const label = this.valueLookup(value);

            defaultValue = label
                ? { label, value }
                : '';
        }

        if (value && !defaultValue) return <div className="form-control main-search" />;

        const components = {};
        if (!showClearableX) {
            // Removes the x that clears the selection on the right of the dropdown
            components.ClearIndicator = () => null;
        }

        return (
            <div className={`text-left ${this.props.containerClassName}`}>
                <AsyncSelect
                        // Supposed to clear the value on backspace, delete, or esc key
                  isClearable={isClearable}
                  components={components}

                  className="countries-select"
                  placeholder="Please select"
                  name={name}
                  id={name}
                  cacheOptions={false}
                  defaultOptions={this.getOptions()}
                  defaultValue={defaultValue}
                  loadOptions={this.loadOptions}
                  theme={this.getTheme}
                  onChange={this.optionSelected}
                  isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}

CountriesAndStates.defaultProps = {
    name: 'country',

    isClearable: true,
    showClearableX: true,
    handleStateInputChange: false,
    updateMethod: false,
    do_lookup: false,
};

// Documentation url: https://react-select.com/home#getting-started

const mapStateToProps = ({ address }) => ({
        countries: address.countries,
    });


export default connect(mapStateToProps, { setSelectedState, getCountries })(CountriesAndStates);
