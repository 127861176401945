import React from 'react'
import LoadingIndicator from './LoadingIndicator'
import { Dropdown } from 'react-bootstrap'
import DropdownToggle from '../DropdownToggle'

const BtnDropdown = ({
    label = 'Actions',
    toggleClassName = '',
    show_loading = false,
    items
}) => (
    <Dropdown>
        <Dropdown.Toggle
            id="dropdown-actions"
            className={`actions-dropdown__toggle w-100 w-xl-auto justify-content-center ${toggleClassName}`}
            labelClassName="m-0 font-weight-bolder font20px"
            as={DropdownToggle}
        >
            <LoadingIndicator show={show_loading} />
            {label}
        </Dropdown.Toggle>
        <Dropdown.Menu className="border border-muted">
            {items.map(item => {
                if (item === 'divider') {
                    return <Dropdown.Divider />
                }

                return <Dropdown.Item {...item}>{item.label}</Dropdown.Item>
            })}
        </Dropdown.Menu>
    </Dropdown>
);
export default BtnDropdown;
