import BaseService from './BaseService';
import { environment } from './environment';
import { GET, POST } from './RequestMethods';

class InvoiceService extends BaseService {
    doInvoiceExport = (search_term) => (
        this.submitRequestWithPromise(POST, environment.apiEndPoint, `export/invoices`, { search_term })
    );

    getInvoices = (params = {}) => {
        const defaults = {
            sort_column: 'invoice_id',
            sort_direction: 'desc',
        };

        return (
            this.submitRequestWithPromise(GET, environment.apiEndPoint, `users/account/invoices`,
                { ...defaults, ...params })
        );
    };

    /* Used for the generate invoice page. This works with a special token. */
    getInvoiceUnAuthed = ({ invoice_id }) => (
        this.submitRequestWithPromise(GET, environment.apiEndPoint, `users/account/invoices/invoice/${invoice_id}`, { scope: 'invoice' })
    );

    getInvoice = ({ invoice_id }) => (
        this.submitRequestWithPromise(GET, environment.apiEndPoint, `users/account/invoices/${invoice_id}`, { scope: 'invoice' })
    );

    processInvoice = ({ invoice_id }) => (
        this.submitRequestWithPromise(POST, environment.apiEndPoint, `users/account/invoices/${invoice_id}/process`, { scope: 'invoice' })
    );
}

export default new InvoiceService();
