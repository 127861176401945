import React from 'react';
import { Loading } from 'components/Loading';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import Carousel from 'components/partials/Carousel/Carousel';
import { nl2br } from 'public/static/js/Utils';
import { Notification } from 'types/Notification';
import 'styles/components/MustKnowModal.scss';

interface MustKnowModalProps {
    show: boolean
    onHide: () => void
    activeSlide?: number
    loading: boolean
    notifications: Notification[]
}

const MustKnowModal: React.FC<MustKnowModalProps> = ({
    show,
    onHide,
    activeSlide = 0,
    loading,
    notifications,
}) => {
    if (loading) {
        return (
            <Loading loading_component={[{ label: 'Loading', loading: loading }]} />
        );
    }

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <ModalHeader closeButton onHide={onHide}>
                <ModalTitle>Stay in the Know</ModalTitle>
            </ModalHeader>
            <ModalBody className="text-left">
                <Carousel
                    name="must-know-carousel"
                    accentColor="primary"
                    minHeight="350"
                    innerClassName="carousel-inner-minvalue"
                    timeout={false}
                    initialSlide={activeSlide}
                    controlsColor="#0F7DBA"
                >
                    {notifications.map((item, index) => (
                        <div key={index}>
                            <div className="mx-5">
                                <p className="font22px font-weight-bold">
                                    {item.subject}
                                </p>
                                <div dangerouslySetInnerHTML={{ __html: nl2br(item.body) }} />
                            </div>
                        </div>
                    ))}
                </Carousel>
            </ModalBody>
        </Modal>
    );
};

export default MustKnowModal;