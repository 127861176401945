import { get } from 'lodash'
import AddressService from 'services/AddressService'
import AuctionFileService from 'services/AuctionFileService'
import AuctionService from 'services/AuctionService'
import FacilityFileService from 'services/FacilityFileService'

import FacilityService from 'services/FacilityService'
import InvoiceService from 'services/InvoiceService'
import LiveAuctionService from 'services/LiveAuctionService'
import NotificationService from 'services/NotificationService'
import PaymentGatewayService from 'services/PaymentGatewayService'
import ReportsService from 'services/ReportsService'
import UserService from 'services/UserService'
import CaseService from '../services/CaseService'
import OfferService from '../services/OfferService'
import FormService from '../services/FormService'
import {
    GET_ADDRESS_AUTOCOMPLETE,
    GET_ADDRESS_AUTOCOMPLETE_FAIL,
    GET_ADDRESS_AUTOCOMPLETE_SUCCESS,
    GET_ADDRESS_COUNTRIES,
    GET_ADDRESS_COUNTRIES_FAIL,
    GET_ADDRESS_COUNTRIES_SUCCESS,
    SET_SELECTED_STATE,
    SET_STATES
} from './AddressTypes'
import {
    AUCTION_CANCEL,
    AUCTION_CANCEL_FAIL,
    AUCTION_CANCEL_FORM,
    AUCTION_CANCEL_SUCCESS,
    AUCTION_DETAIL_AUCTION_WATCH_TOGGLE,
    AUCTION_DETAIL_AUCTION_WATCH_TOGGLE_FAIL,
    AUCTION_DETAIL_AUCTION_WATCH_TOGGLE_SUCCESS,
    AUCTION_IMAGE_EDIT,
    AUCTION_IMAGE_EDIT_FAIL,
    AUCTION_IMAGE_EDIT_SUCCESS,
    AUCTION_IMAGE_GET,
    AUCTION_IMAGE_GET_FAIL,
    AUCTION_IMAGE_GET_SUCCESS,
    AUCTION_POSTING_FORM_CLEAR,
    AUCTION_POSTING_INPUT,
    AUCTION_SAVE_DETAILS,
    AUCTION_SAVE_DETAILS_FAIL,
    AUCTION_SAVE_DETAILS_SUCCESS,
    CLEAR_AUCTION_DATA,
    CLEAR_AUCTION_FILTERS,
    CLEAR_HOME_AUCTION_DATA,
    GET_AUCTION,
    GET_AUCTION_FAIL,
    GET_AUCTION_SUCCESS,
    GET_AUCTIONS_ENDING_SOON,
    GET_AUCTIONS_ENDING_SOON_FAIL,
    GET_AUCTIONS_ENDING_SOON_SUCCESS,
    GET_CATEGORIES,
    GET_CATEGORIES_FAIL,
    GET_CATEGORIES_SUCCESS,
    GET_COMING_SOON_AUCTIONS,
    GET_COMING_SOON_AUCTIONS_FAIL,
    GET_COMING_SOON_AUCTIONS_SUCCESS,
    GET_ONLINE_AUCTIONS,
    GET_ONLINE_AUCTIONS_FAIL,
    GET_ONLINE_AUCTIONS_SUCCESS,
    GET_TOP_AUCTIONS,
    GET_TOP_AUCTIONS_FAIL,
    GET_TOP_AUCTIONS_SUCCESS,
    POST_AUCTION,
    POST_AUCTION_FAIL,
    POST_AUCTION_SUCCESS,
    SAVE_AUCTION_AS_DRAFT,
    SAVE_AUCTION_AS_DRAFT_FAIL,
    SAVE_AUCTION_AS_DRAFT_SUCCESS,
    SET_FILTER_CATEGORY,
    UPDATE_AUCTIONS_ENDING_SOON,
    UPDATE_TOP_AUCTIONS,
    EXPIRES_IN,
    SET_NAVIGATING_TO_AUCTION
} from './AuctionTypes'
import { BID_MANAGER_UPDATE, BID_MANAGER_UPDATE_FAIL, BID_MANAGER_UPDATE_SUCCESS } from './BidManagerTypes'
import {
    ACTIVE_AUCTIONS_INPUT_CHANGED,
    ACTIVE_SEARCH_INPUT_CHANGED,
    CANCEL_LIVE_AUCTION,
    CANCEL_LIVE_AUCTION_FAIL,
    CANCEL_LIVE_AUCTION_SUCCESS,
    CANCELED_AFTER_SOLD_AUCTIONS_INPUT_CHANGED,
    CANCELED_AFTER_SOLD_SEARCH_INPUT_CHANGED,
    CANCELED_AUCTIONS_INPUT_CHANGED,
    CANCELED_SEARCH_INPUT_CHANGED,
    CUSTOM_DATE_INPUT_CHANGED,
    DELETE_DRAFT_AUCTION,
    DELETE_DRAFT_AUCTION_FAIL,
    DELETE_DRAFT_AUCTION_SUCCESS,
    DRAFT_AUCTIONS_INPUT_CHANGED,
    DRAFT_SEARCH_INPUT_CHANGED,
    FACILITIES_SEARCH_INPUT_CHANGED,
    FACILITY_MANAGER_GET_AUCTION_DETAILS,
    FACILITY_MANAGER_GET_AUCTION_DETAILS_FAIL,
    FACILITY_MANAGER_GET_AUCTION_DETAILS_SUCCESS,
    FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS,
    FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_FAIL,
    FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_SUCCESS,
    FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE,
    FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_FAIL,
    FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_SUCCESS,
    FACILITY_MANAGER_LIVE_AUCTION_FORM_UPDATE,
    FACILITY_MANAGER_METRICS,
    FACILITY_MANAGER_METRICS_FAIL,
    FACILITY_MANAGER_METRICS_SUCCESS,
    FACILITY_MANAGER_TOP_AUCTIONS,
    FACILITY_MANAGER_TOP_AUCTIONS_FAIL,
    FACILITY_MANAGER_TOP_AUCTIONS_SUCCESS,
    GET_FM_ACTIVE_AUCTIONS,
    GET_FM_ACTIVE_AUCTIONS_FAIL,
    GET_FM_ACTIVE_AUCTIONS_SUCCESS,
    GET_FM_CANCELED_AFTER_SOLD_AUCTIONS,
    GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_FAIL,
    GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_SUCCESS,
    GET_FM_CANCELED_AUCTIONS,
    GET_FM_CANCELED_AUCTIONS_FAIL,
    GET_FM_CANCELED_AUCTIONS_SUCCESS,
    GET_FM_DRAFT_AUCTIONS,
    GET_FM_DRAFT_AUCTIONS_FAIL,
    GET_FM_DRAFT_AUCTIONS_SUCCESS,
    GET_FM_FACILITIES,
    GET_FM_FACILITIES_FAIL,
    GET_FM_FACILITIES_SUCCESS,
    GET_FM_IMPORTED_AUCTIONS,
    GET_FM_IMPORTED_AUCTIONS_FAIL,
    GET_FM_IMPORTED_AUCTIONS_SUCCESS,
    GET_FM_LIVE_AUCTIONS,
    GET_FM_LIVE_AUCTIONS_FAIL,
    GET_FM_LIVE_AUCTIONS_SUCCESS,
    GET_FM_SOLD_AUCTIONS,
    GET_FM_SOLD_AUCTIONS_FAIL,
    GET_FM_SOLD_AUCTIONS_SUCCESS,
    GET_FM_UNSOLD_AUCTIONS,
    GET_FM_UNSOLD_AUCTIONS_FAIL,
    GET_FM_UNSOLD_AUCTIONS_SUCCESS,
    IMPORTED_SEARCH_INPUT_CHANGED,
    LIVE_AUCTIONS_INPUT_CHANGED,
    LIVE_SEARCH_INPUT_CHANGED,
    SOLD_AUCTIONS_INPUT_CHANGED,
    SOLD_SEARCH_INPUT_CHANGED,
    UNSOLD_AUCTIONS_INPUT_CHANGED,
    UNSOLD_SEARCH_INPUT_CHANGED
} from './FacilityManagerTypes'

import {
    BOOKMARK_FACILITY,
    BOOKMARK_FACILITY_FAIL,
    BOOKMARK_FACILITY_SUCCESS,
    FACILITY_LOAD_FEATURES,
    FACILITY_LOAD_FEATURES_FAIL,
    FACILITY_LOAD_FEATURES_SUCCESS,
    FACILITY_SAVE,
    FACILITY_SAVE_FAIL,
    FACILITY_SAVE_SUCCESS,
    GET_FACILITIES_FAIL,
    GET_FACILITY,
    GET_FACILITY_LIVE_AUCTIONS,
    GET_FACILITY_LIVE_AUCTIONS_FAIL,
    GET_FACILITY_LIVE_AUCTIONS_SUCCESS,
    GET_FACILITY_ONLINE_AUCTIONS,
    GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON,
    GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_FAIL,
    GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_SUCCESS,
    GET_FACILITY_ONLINE_AUCTIONS_FAIL,
    GET_FACILITY_ONLINE_AUCTIONS_SUCCESS,
    GET_FACILITY_PMS,
    GET_FACILITY_SUCCESS,
    GET_USER_FACILITIES,
    GET_USER_FACILITIES_FAIL,
    GET_USER_FACILITIES_SUCCESS,
    GET_USER_FACILITY,
    GET_USER_FACILITY_FAIL,
    GET_USER_FACILITY_SUCCESS,
    SET_FACILITY_EDIT_FORM_DATA
} from './FacilityTypes'
import { FILE_POST, FILE_POST_RESET } from './FileTypes'
import { FORM_REQUEST_CSRF_TOKEN, FORM_SAVING_DONE, FORM_SAVING_START } from './FormTypes'
import {
    DO_INVOICE_EXPORT,
    DO_INVOICE_EXPORT_FAIL,
    DO_INVOICE_EXPORT_SUCCESS,
    GET_INVOICE,
    GET_INVOICE_FAIL,
    GET_INVOICE_SUCCESS,
    GET_INVOICES,
    GET_INVOICES_FAIL,
    GET_INVOICES_SUCCESS,
    INVOICE_INPUT_FIELD_CHANGE,
    UPDATE_INVOICE
} from './InvoiceTypes'
import { GET_LIVE_AUCTION_FAIL, GET_LIVE_AUCTION_SUCCESS, GET_LIVE_AUCTIONS } from './LiveAuctionTypes'
import {
    GET_MUST_KNOW_NOTIFICATION,
    GET_MUST_KNOW_NOTIFICATION_FAIL,
    GET_MUST_KNOW_NOTIFICATION_SUCCESS,
    GET_MUST_KNOW_NOTIFICATIONS,
    GET_MUST_KNOW_NOTIFICATIONS_FAIL,
    GET_MUST_KNOW_NOTIFICATIONS_SUCCESS
} from './NotificationTypes'

import {
    CLEAR_ALERT,
    PAGE_AS_ROUTE,
    SCREEN_RESIZE,
    SEO_CLEAR,
    SEO_SET,
    SET_DASHBOARD_PAGE,
    SET_GLOBAL_ALERT,
    SET_PAGE,
    SET_PAGE_CONTAINER_CLASS
} from './PageTypes'
import { LOAD_PAYMENT_TOKEN, LOAD_PAYMENT_TOKEN_FAIL, LOAD_PAYMENT_TOKEN_SUCCESS } from './PaymentGatewayTypes'

import {
    REGISTRATION_INPUT_CHANGE,
    REGISTRATION_INPUT_CLEAR,
    REGISTRATION_STEP1_DONE,
    REGISTRATION_STEP2_DONE,
    SET_VERIFICATION_QUESTIONS_STATUS,
    CORRECT_ANSWERS_IDENTYFLOW
} from './RegistrationTypes'
import { GET_REPORTS, GET_REPORTS_FAIL, GET_REPORTS_SUCCESS } from './ReportsTypes'

import { THINGAMABOB_CLEAR_ACTIONS, THINGAMABOB_SET_ACTIONS, THINGAMABOB_SET_PROP } from './ThingamabobTypes'
import {
    AUCTION_BID_SUBMIT,
    AUCTION_BID_SUBMIT_FAIL,
    AUCTION_BID_SUBMIT_SUCCESS,
    CHANGE_MEMBERSHIP,
    CHANGE_MEMBERSHIP_FAIL,
    CHANGE_MEMBERSHIP_SUCCESS,
    CHECK_USER_DUPLICATE_EMAIL, CHECK_USER_DUPLICATE_PHONE,
    CREATE_USER_NOTIFICATION,
    CREATE_USER_NOTIFICATION_FAIL,
    CREATE_USER_NOTIFICATION_SUCCESS,
    DELETE_USER_BOOKMARKED_FACILITY,
    DELETE_USER_BOOKMARKED_FACILITY_FAIL,
    DELETE_USER_BOOKMARKED_FACILITY_SUCCESS,
    DELETE_USER_NOTIFICATION,
    DELETE_USER_NOTIFICATION_FAIL,
    DELETE_USER_NOTIFICATION_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_CHANGE,
    FORGOT_PASSWORD_CHANGE_FAIL,
    FORGOT_PASSWORD_CHANGE_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD_SUCCESS,
    GET_HEARD_QUESTIONS,
    GET_SECURITY_QUESTIONS,
    GET_USER_AUCTION_WATCH,
    GET_USER_AUCTION_WATCH_FAIL,
    GET_USER_AUCTION_WATCH_SUCCESS,
    GET_USER_AUCTIONS_WITH_BIDS,
    GET_USER_AUCTIONS_WITH_BIDS_FAIL,
    GET_USER_AUCTIONS_WITH_BIDS_SUCCESS,
    GET_USER_BOOKMARKED_FACILITIES,
    GET_USER_BOOKMARKED_FACILITIES_FAIL,
    GET_USER_BOOKMARKED_FACILITIES_SUCCESS,
    GET_USER_NOTIFICATION,
    GET_USER_NOTIFICATION_FAIL,
    GET_USER_NOTIFICATION_SUCCESS,
    GET_USER_NOTIFICATIONS,
    GET_USER_NOTIFICATIONS_FAIL,
    GET_USER_NOTIFICATIONS_SUCCESS,
    GET_WON_AUCTIONS,
    GET_WON_AUCTIONS_FAIL,
    GET_WON_AUCTIONS_SUCCESS,
    NOTIFICATIONS_FORM_CLEAR,
    NOTIFICATIONS_INPUT_CHANGED,
    REMOVE_USER_WATCHED_AUTION,
    REMOVE_USER_WATCHED_AUTION_FAIL,
    REMOVE_USER_WATCHED_AUTION_SUCCESS,
    SET_FORMATTED_TABLE_DATA,
    SETTINGS_FORM_CLEAR,
    SETTINGS_INPUT_CHANGED,
    UPDATE_USER_NOTIFICATION,
    UPDATE_USER_NOTIFICATION_FAIL,
    UPDATE_USER_NOTIFICATION_SUCCESS,
    UPGRADE_FORM_CLEAR,
    UPGRADE_INPUT_FORM_CHANGE,
    USER_BID_BANNER,
    USER_CHECKING_PROMO_CODE,
    USER_CHECKING_PROMO_CODE_FAIL,
    USER_CHECKING_PROMO_CODE_SUCCESS,
    USER_LOGIN,
    USER_LOGOUT,
    USER_PERSIST_LOGIN,
    USER_SETTINGS_GET,
    USER_SETTINGS_GET_FAIL,
    USER_SETTINGS_GET_SUCCESS,
    USER_SETTINGS_PAYMENT_METHOD,
    USER_SETTINGS_SAVE,
    USER_SETTINGS_SAVE_FAIL,
    USER_SETTINGS_SAVE_SUCCESS,
    GET_USER_PERMISSIONS,
    GET_USER_PERMISSIONS_SUCCESS,
    GET_USER_PERMISSIONS_FAIL,
    GET_ID_VERIFICATION_QUESTIONS,
    GET_ID_VERIFICATION_QUESTIONS_SUCCESS,
    GET_ID_VERIFICATION_QUESTIONS_FAIL,
    GET_USER_PICKUP_PERSONS,
    GET_USER_PICKUP_PERSONS_SUCCESS,
    GET_USER_PICKUP_PERSONS_FAIL
} from './UserTypes'
import { GET_OFFER, GET_OFFER_SUCCESS, GET_OFFER_FAIL, UPDATE_OFFER, UPDATE_OFFER_FAIL } from './OfferTypes'
import IdImageService from "../services/IdImageService"
import Cookies from 'js-cookie'

export const getOnlineAuctions = ({ loading_only = false, ...search_obj }, { show_loading = true } = {}) => async (dispatch) => new Promise((resolve, reject) => {
    if (show_loading) {
        dispatch({ type: GET_ONLINE_AUCTIONS })
    }

    if (loading_only === true) {
        resolve()
        return true
    }

    AuctionService.getOnlineAuctions(search_obj).then(
        data => {
            dispatch({ type: GET_ONLINE_AUCTIONS_SUCCESS, payload: data })
            resolve(data)
        },
        response => {
            dispatch({ type: GET_ONLINE_AUCTIONS_FAIL, payload: response })
            reject()
        }
    )
})

export const getUpcomingAuctions = ({ loading_only = false, ...search_obj }, { show_loading = true } = {}) => async (dispatch) => new Promise((resolve, reject) => {
    if (show_loading) {
        dispatch({ type: GET_COMING_SOON_AUCTIONS })
    }

    if (loading_only === true) {
        resolve()
        return true
    }

    AuctionService.getComingSoonAuctions(search_obj).then(
        data => {
            dispatch({ type: GET_COMING_SOON_AUCTIONS_SUCCESS, payload: data })
            resolve(data)
        },
        response => {
            dispatch({ type: GET_COMING_SOON_AUCTIONS_FAIL, payload: response })
            reject(response)
        }
    )
})

export const getUserAuctionWatch = (dashboard = false, page = 1, page_count = 30, sort_column = 'expire_date', sort_direction = 'asc') => async (dispatch) => {
    dispatch({ type: GET_USER_AUCTION_WATCH })

    UserService.getUserAuctionWatch(dashboard, page, page_count, sort_column, sort_direction)
        .then(data => {
            dispatch({ type: GET_USER_AUCTION_WATCH_SUCCESS, payload: data })
        })
        .catch(response => {
            dispatch({ type: GET_USER_AUCTION_WATCH_FAIL, payload: response })
        })
}

export const removeWatchedAuction = (auction_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: REMOVE_USER_WATCHED_AUTION })

    UserService.deleteAuctionWatch(auction_id)
        .then(payload => {
            dispatch({ type: REMOVE_USER_WATCHED_AUTION_SUCCESS, payload })
            resolve()
        })
        .catch(payload => {
            dispatch({ type: REMOVE_USER_WATCHED_AUTION_FAIL, payload })
            reject()
        })
})

export const clearAuctionData = () => async (dispatch) => {
    dispatch({ type: CLEAR_AUCTION_DATA })
}

export const clearAuctionFilters = () => async (dispatch) => {
    dispatch({ type: CLEAR_AUCTION_FILTERS })
}

export const clearHomeAuctionData = () => async (dispatch) => {
    dispatch({ type: CLEAR_HOME_AUCTION_DATA })
}

export const setFilterCategory = (category_id) => async (dispatch) => {
    dispatch({ type: SET_FILTER_CATEGORY, payload: category_id })
}

export const getAuction = (
    auction_id, refresh, continue_polling = true, user_ip = undefined,
    { skip_loading = false, return_full_obj = false } = {}, ignore_redirect = false) => async (dispatch) => new Promise((resolve, reject) => {
    if (!continue_polling) {
        resolve()
        return false
    }

    if (!refresh && !skip_loading) {
        dispatch({ type: GET_AUCTION })
    }

    AuctionService.getAuction(auction_id, refresh, user_ip, ignore_redirect).then(data => {
        dispatch({ type: GET_AUCTION_SUCCESS, payload: data })

        if (return_full_obj) {
            resolve(data)
            return true
        }

        resolve(data.auction)
    }).catch(response => {
        dispatch({ type: GET_AUCTION_FAIL, payload: response })

        reject(response)
    })
})

export const getCategories = () => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_CATEGORIES })

    AuctionService.getAuctionCategories().then(
        payload => {
            dispatch({ type: GET_CATEGORIES_SUCCESS, payload })
            resolve(payload)
        },
        payload => {
            dispatch({ type: GET_CATEGORIES_FAIL, payload })
            reject(payload)
        },
    )
})

export const triggerFacilityHomepageApis = (facility_id) => async (dispatch) => {
    dispatch(getFacilityOnlineAuction(facility_id, 1))
    dispatch(getFacilityOnlineAuctionComingSoon(facility_id, 1))
    dispatch(getFacilityLiveAuction(facility_id, 1))
}

export const getFacility = (facility_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_FACILITY })

    // Make the api call then dispatch
    FacilityService.getFacility(facility_id)
        .then(
            data => {
                dispatch({ type: GET_FACILITY_SUCCESS, payload: data })
                resolve(data)
            },
            response => {
                dispatch({ type: GET_FACILITIES_FAIL, payload: response })
                reject(response)
            }
        )
})

export const getFacilityByStoreId = ({ account_id, custom_id }) => dispatch => new Promise((resolve, reject) => {
    FacilityService.getFacilityIdFromCustomId({ account_id, custom_id }).then(resolve).catch(reject)
})

export const getFacilityFile = ({ auction_id, draft_id, auction_status }) => async (dispatch) => new Promise((resolve, reject) => {
    FacilityService.getLienFile({ auction_id, draft_id, auction_status }).then(resolve, reject)
})

export const getFacilityImages = ({ facility_id }) => async (dispatch) => new Promise((resolve, reject) => {
    FacilityService.getImages({ facility_id }).then(resolve, reject)
})

export const getUserFacilityList = ({ loadingLiveAuction = false } = {}) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_FACILITIES })

    if (loadingLiveAuction) {
        dispatch({ type: FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS })
        resolve()
    }

    FacilityService.getUserFacilityList().then(
        payload => {
            resolve(payload)
            dispatch({ type: GET_USER_FACILITIES_SUCCESS, payload })
        },
        payload => {
            reject()
            dispatch({ type: GET_USER_FACILITIES_FAIL, payload })
        }
    )
})

export const getFacilityOnlineAuction = (facility_id, page_num) => async (dispatch) => {
    dispatch({ type: GET_FACILITY_ONLINE_AUCTIONS })

    return FacilityService.getFacilityOnlineAuction(facility_id, page_num)
        .then(data => dispatch({ type: GET_FACILITY_ONLINE_AUCTIONS_SUCCESS, payload: data }))
        .catch(response => dispatch({ type: GET_FACILITY_ONLINE_AUCTIONS_FAIL, payload: response }))
}

export const getFacilityOnlineAuctionComingSoon = (facility_id, page_num) => async (dispatch) => {
    dispatch({ type: GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON })

    FacilityService.getFacilityOnlineAuctionComingSoon(facility_id, page_num)
        .then(data => dispatch({ type: GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_SUCCESS, payload: data }))
        .catch(response => dispatch({ type: GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_FAIL, payload: response }))
}

export const getFacilityLiveAuction = (facility_id, page_num) => async (dispatch) => {
    dispatch({ type: GET_FACILITY_LIVE_AUCTIONS })

    FacilityService.getFacilityLiveAuction(facility_id, page_num)
        .then(data => dispatch({ type: GET_FACILITY_LIVE_AUCTIONS_SUCCESS, payload: data }))
        .catch(response => dispatch({ type: GET_FACILITY_LIVE_AUCTIONS_FAIL, payload: response }))
}

export const bookmarkFacility = ({ facility_id, direction, bookmark_id = false }) => async (dispatch) => {
    dispatch({ type: BOOKMARK_FACILITY, facility_id, direction })
    FacilityService.bookmarkFacility({ facility_id, bookmark_id, direction }).then(
        payload => {
            dispatch({ type: BOOKMARK_FACILITY_SUCCESS, facility_id, direction, payload })
        },
        error => {
            dispatch({ type: BOOKMARK_FACILITY_FAIL, error })
        }
    )
}

export const getUserBookmarkedFacilities = (dashboard = false, page = 0, page_count = 30, sort_column = 'facility_name', sort_direction = 'asc') => async (dispatch) => {
    dispatch({ type: GET_USER_BOOKMARKED_FACILITIES })

    UserService.getUserBookmarkedFacilities(dashboard, page, page_count, sort_column, sort_direction)
        .then(data => {
            dispatch({ type: GET_USER_BOOKMARKED_FACILITIES_SUCCESS, payload: data })
        })
        .catch(response => {
            dispatch({ type: GET_USER_BOOKMARKED_FACILITIES_FAIL, payload: response })
        })
}

export const deleteUserBookmarkedFacility = (bookmark_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: DELETE_USER_BOOKMARKED_FACILITY })

    UserService.deleteUserBookmarkedFacility(bookmark_id)
        .then(data => {
            dispatch({ type: DELETE_USER_BOOKMARKED_FACILITY_SUCCESS, payload: data })
            resolve()
        })
        .catch(response => {
            dispatch({ type: DELETE_USER_BOOKMARKED_FACILITY_FAIL, payload: response })
            reject()
        })
})

export const setPage = ({ h1, title, marginBottom, description }) => ({
    dispatch: { type: SET_PAGE, h1, title, marginBottom, description }
})

export const getUserAuctionsWithBids = ({ term = '', page_num = 0, page_count = 30, sort_column = 'high_bid', sort_direction = "desc", loading = true }) => async (dispatch) => new Promise((resolve, reject) => {
    if (loading === true) {
        dispatch({ type: GET_USER_AUCTIONS_WITH_BIDS })
    }

    UserService.getUserAuctionsWithBids({ term, page_num, page_count, sort_column, sort_direction })
        .then(data => {
            resolve(data)
            dispatch({ type: GET_USER_AUCTIONS_WITH_BIDS_SUCCESS, payload: data })
        })
        .catch(response => {
            reject(response)
            dispatch({ type: GET_USER_AUCTIONS_WITH_BIDS_FAIL, payload: response })
        })
})

export const bidManagerUpdateBid = ({ auction_id, current_bid, user_max_bid, loading = true }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: BID_MANAGER_UPDATE })


    const ga_session_id = Cookies.get('_ga_YM2Y56PMJX')

    UserService.submitBid({ auction_id, max_bid: user_max_bid, ga_session_id })
        .then(
            payload => {
                resolve(payload)
                dispatch({ type: BID_MANAGER_UPDATE_SUCCESS, payload })
            },
            payload => {
                reject(payload)
                dispatch({ type: BID_MANAGER_UPDATE_FAIL, payload })
            }
        )
})

export const screenResize = ({ width }) => async (dispatch) => {
    dispatch({ type: SCREEN_RESIZE, width: width })
}

/*
 * close_after int time in seconds
 */
export const setGlobalAlert = ({ title, message, alert_type, close_after, keepalive }) => async (dispatch) => {
    dispatch({ type: SET_GLOBAL_ALERT, title: title, message: message, alert_type: alert_type, close_after, keepalive })
}

export const clearAlerts = () => async (dispatch) => {
    dispatch({ type: CLEAR_ALERT })
}

export const handleRegInputChange = ({ target }, input_type) => async (dispatch, getStore) => {
    const { name, value } = target

    dispatch({ type: REGISTRATION_INPUT_CHANGE, name, value, input_type })
}

export const registrationSelectedTimezone = (timezone) => async (dispatch) => {
    dispatch({ type: REGISTRATION_INPUT_CHANGE, name: 'timezone_id', value: timezone })
}

export const clearRegForm = () => async (dispatch) => {
    dispatch({ type: REGISTRATION_INPUT_CLEAR })
}
export const clearRegFormComplete = () => async (dispatch) => {
    dispatch({ type: REGISTRATION_INPUT_CLEAR, withUser: true })
}

export const setVerificationQuestionsStatus = (payload) => async (dispatch) => {
    dispatch({ type: SET_VERIFICATION_QUESTIONS_STATUS, payload })
}

export const updateCorrectAnswers = ( correctAnswersIdentyFlow ) => async (dispatch) => {
    dispatch({ type: CORRECT_ANSWERS_IDENTYFLOW, payload: correctAnswersIdentyFlow })
}
export const regFormSaveStep1 = (user_params, csrf_token) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: REGISTRATION_STEP1_DONE })

    UserService.createUser(user_params, csrf_token).then(
        payload => {
            dispatch({ type: REGISTRATION_STEP1_DONE + "_SUCCESS", payload })
            resolve(payload)
        },
        payload => {
            dispatch({ type: REGISTRATION_STEP1_DONE + "_FAIL", payload })
            reject(payload)
        }
    )
})

export const regFormSaveStep2 = (facility_params, csrf_token) => async (dispatch) => new Promise(resolve => {
    dispatch({ type: REGISTRATION_STEP2_DONE })

    FacilityService.createFacility(facility_params, csrf_token).then(payload => {
        resolve(payload)
        return dispatch({ type: REGISTRATION_STEP2_DONE + '_SUCCESS', payload })
    }).catch(payload => dispatch({ type: REGISTRATION_STEP2_DONE + '_FAIL', payload }))
})

//TODO:: after we go live remove this function in all the places it's used.
export const setThingamabobProp = (name, value) => async (dispatch) => {
    dispatch({ type: THINGAMABOB_SET_PROP, name, value })
}

export const setThingamabobActions = (actions = []) => async (dispatch) => {
    dispatch({ type: THINGAMABOB_SET_ACTIONS, actions: actions })
}

export const clearThingamabobActions = () => async (dispatch) => {
    dispatch({ type: THINGAMABOB_CLEAR_ACTIONS })
}

export const specialCheckPassword = (password, password_confirm, reset = false, params = {}) => async (dispatch) => {
    return UserService.passwordCheck(password, password_confirm, params)
}

export const resetPasswordCheckLink = ({ l }) => async (dispatch) => new Promise((resolve, reject) => {
    UserService.resetPasswordCheckLink({ l }).then(
        success => resolve(success),
        error => reject(error),
    )
})

export const setPageContainerClass = ({ containerClass }) => async (dispatch) => {
    dispatch({ type: SET_PAGE_CONTAINER_CLASS, containerClass })
}

export const searchLiveAuctions = (search_obj) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_LIVE_AUCTIONS })

    LiveAuctionService.getLiveAuctions(search_obj).then(
        payload => {
            dispatch({ type: GET_LIVE_AUCTION_SUCCESS, payload })
            resolve(payload.auction)
        },
        payload => {
            dispatch({ type: GET_LIVE_AUCTION_FAIL, payload })
            reject()
        }
    )
})

export const getCountries = () => ({
    pre_type: GET_ADDRESS_COUNTRIES,

    async_service_call: AddressService.getCountries(),

    success: GET_ADDRESS_COUNTRIES_SUCCESS,
    failed: GET_ADDRESS_COUNTRIES_FAIL,
})

export const getAddresses = () => ({
    pre_type: GET_ADDRESS_AUTOCOMPLETE,

    async_service_call: AddressService.getAddresses(),

    success: GET_ADDRESS_AUTOCOMPLETE_SUCCESS,
    failed: GET_ADDRESS_AUTOCOMPLETE_FAIL,
})

export const setSelectedState = (state) => async (dispatch) => {
    dispatch({ type: SET_SELECTED_STATE, state })
}

export const triggerHomepageApis = (refresh, limit = undefined) => async (dispatch) => {
    dispatch(getTopAuctions(refresh, limit))
    dispatch(getAuctionsEndingSoon(refresh, limit))
    dispatch(getMustKnowNotifications(1))
}

export const getTopAuctions = (refresh, limit = undefined) => async (dispatch) => new Promise((resolve, reject) => {
    if (!refresh) {
        dispatch({ type: GET_TOP_AUCTIONS })
    } else {
        dispatch({ type: UPDATE_TOP_AUCTIONS })
    }

    AuctionService.getTopAuctions(limit)
        .then(data => {
            dispatch({ type: GET_TOP_AUCTIONS_SUCCESS, payload: data.auctions })
            resolve(data)
        })
        .catch(response => {
            reject(response)
            dispatch({ type: GET_TOP_AUCTIONS_FAIL, payload: response })
        })
})

export const getFacilityManagerTopAuctions = ({ page_count = 10 } = {}) => async (dispatch) => {
    dispatch({ type: FACILITY_MANAGER_TOP_AUCTIONS })

    FacilityService.getFacilityManagerTopAuctions({ page_count }).then(
        payload => dispatch({ type: FACILITY_MANAGER_TOP_AUCTIONS_SUCCESS, payload }),
        error => dispatch({ type: FACILITY_MANAGER_TOP_AUCTIONS_FAIL, error })
    )
}

export const getAuctionsEndingSoon = (refresh, limit = undefined) => async (dispatch) => new Promise((resolve, reject) => {
    if (!refresh) {
        dispatch({ type: GET_AUCTIONS_ENDING_SOON })
    } else {
        dispatch({ type: UPDATE_AUCTIONS_ENDING_SOON })
    }

    AuctionService.getAuctionsEndingSoon(limit)
        .then(data => {
            resolve(data)
            dispatch({ type: GET_AUCTIONS_ENDING_SOON_SUCCESS, payload: data.auctions })
        })
        .catch(response => {
            reject(response)
            dispatch({ type: GET_AUCTIONS_ENDING_SOON_FAIL, payload: response })
        })
})

export const getMustKnowNotifications = (location_id, { clear = false } = {}) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_MUST_KNOW_NOTIFICATIONS, clear })

    if (clear) {
        resolve()
        return true
    }

    NotificationService.getMustKnowNotifications(location_id)
        .then(payload => {
            dispatch({ type: GET_MUST_KNOW_NOTIFICATIONS_SUCCESS, payload: payload.notifications })
            resolve(payload.notifications)
        })
        .catch(payload => {
            dispatch({ type: GET_MUST_KNOW_NOTIFICATIONS_FAIL, payload })
            reject()
        })
})

export const getMustKnowNotification = (must_know_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_MUST_KNOW_NOTIFICATION })

    NotificationService.getMustKnowNotification(must_know_id)
        .then(payload => {
            dispatch({ type: GET_MUST_KNOW_NOTIFICATION_SUCCESS, payload: payload.notifications })
            resolve(payload.notifications)
        })
        .catch(payload => {
            dispatch({ type: GET_MUST_KNOW_NOTIFICATION_FAIL, payload })
            reject()
        })
})

export const countrySelected = (country_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: SET_STATES, country_id, resolve, reject })
})

export const checkIfDuplicateEmail = (email) => async (dispatch) => new Promise((resolve, reject) => {

    if (email.length < 3 || !/\S+@\S+\.\S+/.test(email)) {
        dispatch({ type: CHECK_USER_DUPLICATE_EMAIL, isDuplicateEmail: null })
    } else {
        UserService.checkEmailExists(email)
            .then(payload => {
                if (payload.message == 'true') {
                    resolve(true)
                } else {
                    resolve(false)
                }
                dispatch({ type: CHECK_USER_DUPLICATE_EMAIL, isDuplicateEmail: payload.message == "true" })
            })
            .catch(payload => {
                dispatch({ type: CHECK_USER_DUPLICATE_EMAIL + "_FAIL", payload })
                resolve(false)
            })
    }
})

export const checkIfDuplicatePhone = (phone) => async (dispatch) => new Promise((resolve, reject) => {
    UserService.checkPhoneExists(phone)
        .then(payload => {
            if (payload.exists) {
                resolve(true)
            } else {
                resolve(false)
            }
            dispatch({ type: CHECK_USER_DUPLICATE_PHONE, isDuplicatePhone: payload.exists == true })
        })
        .catch(payload => {
            dispatch({ type: CHECK_USER_DUPLICATE_PHONE + "_FAIL", payload })
            resolve(false)
        })
})

export const getPMSSystems = () => async (dispatch) => new Promise((resolve) => {
    dispatch({ type: GET_FACILITY_PMS })

    FacilityService.getPMSSystems()
        .then(payload => {
            dispatch({ type: GET_FACILITY_PMS + "_SUCCESS", pms_systems: payload.pms_options })
            resolve()
        })
        .catch(payload => {
            dispatch({ type: GET_FACILITY_PMS + "_FAIL", error: payload })
            resolve()
        })
})

export const getHeardQuestions = () => async (dispatch) => {
    dispatch({ type: GET_HEARD_QUESTIONS })

    UserService.getHeardQuestions()
        .then(payload => {
            dispatch({ type: GET_HEARD_QUESTIONS + '_SUCCESS', heard_about_us: payload.heard_about_us })
        })
        .catch(payload => {
            dispatch({ type: GET_SECURITY_QUESTIONS + '_FAIL', payload })
        })
}

export const getSecurityQuestions = () => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_SECURITY_QUESTIONS })

    UserService.getSecurityQuestions()
        .then(payload => {
            resolve(payload)
            dispatch({ type: GET_SECURITY_QUESTIONS + '_SUCCESS', security_questions: payload.security_questions })
        })
        .catch(payload => {
            reject(payload)
            dispatch({ type: GET_SECURITY_QUESTIONS + '_FAIL', payload })
        })
})

export const setDashboardPage = ({ title, menuEnum, dashboard_menu_item = false, pageAction = false }) => ({
    dispatch: { type: SET_DASHBOARD_PAGE, dashboard_title: title, dashboard_menu_enum: menuEnum, dashboard_menu_item, pageAction }
})


export const getUserNotificationById = ({ notificationId }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_NOTIFICATION })

    UserService.getUserNotificationById(notificationId)
        .then(payload => {
            resolve(payload)
            dispatch({ type: GET_USER_NOTIFICATION_SUCCESS, user_notification: payload })
        })
        .catch(payload => {
            reject(payload)
            dispatch({ type: GET_USER_NOTIFICATION_FAIL, payload })
        })
})

export const getUserNotifications = ({ term = '', page = 0, page_count = 30, sort_column = 'close_date', sort_direction = "desc" }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_NOTIFICATIONS })

    UserService.getUserNotifications({ term, page, page_count, sort_column, sort_direction })
        .then(payload => {
            resolve(payload)
            dispatch({ type: GET_USER_NOTIFICATIONS_SUCCESS, user_notifications: payload.auction_notifications })
        })
        .catch(payload => {
            reject(payload)
            dispatch({ type: GET_USER_NOTIFICATIONS_FAIL, payload })
        })
})

export const createUserNotification = ({ notification }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: CREATE_USER_NOTIFICATION })

    UserService.createUserNotification(notification)
        .then(payload => {
            notification.zipcode = notification.search.zipcode
            notification.radius = notification.search.radius
            notification.notification_id = payload.notification_id

            resolve()

            dispatch({ type: CREATE_USER_NOTIFICATION_SUCCESS, user_notification: notification })
        })
        .catch(payload => {
            reject(payload)
            dispatch({ type: CREATE_USER_NOTIFICATION_FAIL, payload })
        })
})

export const updateUserNotification = ({ notification }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: UPDATE_USER_NOTIFICATION })

    UserService.updateUserNotification(notification)
        .then(payload => {
            notification.zipcode = notification.search.zipcode
            notification.radius = notification.search.radius

            resolve()

            dispatch({ type: UPDATE_USER_NOTIFICATION_SUCCESS, user_notification: notification })
        })
        .catch(payload => {
            reject(payload)

            dispatch({ type: UPDATE_USER_NOTIFICATION_FAIL, payload })
        })
})

export const deleteUserNotification = ({ notificationId }) => async (dispatch) => new Promise((resolve) => {
    dispatch({ type: DELETE_USER_NOTIFICATION })

    UserService.deleteUserNotification(notificationId)
        .then(payload => {
            resolve()
            dispatch({ type: DELETE_USER_NOTIFICATION_SUCCESS, notification_id: notificationId })
        })
        .catch(payload => {
            resolve()
            dispatch({ type: DELETE_USER_NOTIFICATION_FAIL, payload })
        })
})

export const notificationsFormInputChanged = (event, checked) => async (dispatch) => {
    dispatch({ type: NOTIFICATIONS_INPUT_CHANGED, name: event.target.name, value: event.target.value, checked: checked })
}

export const notificationsFormClear = () => async (dispatch) => {
    dispatch({ type: NOTIFICATIONS_FORM_CLEAR })
}

export const invoiceSearchFieldOnChange = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: INVOICE_INPUT_FIELD_CHANGE, key, value })
}

export const getInvoices = (search = '', options = {}) => async (dispatch) => {
    dispatch({ type: GET_INVOICES })

    InvoiceService.getInvoices(search, options)
        .then(res => {
            dispatch({ type: GET_INVOICES_SUCCESS, payload: res })
        })
        .catch(error => {
            dispatch({ type: GET_INVOICES_FAIL, error })
        })
}

export const getInvoice = ({ invoice_id = false, loading_only = false, reset = false }) => async (dispatch) => new Promise((resolve, reject) => {
    if (reset === true) {
        dispatch({
            type: GET_INVOICE,
            reset
        })
        resolve()
        return true
    }

    // trigger loading and reset invoice reducer state
    dispatch({ type: GET_INVOICE })

    if (loading_only === true) {
        resolve()
        return true
    }

    if (invoice_id !== false) {
        InvoiceService.getInvoice({ invoice_id })
            .then(
                payload => {
                    resolve(payload)
                    dispatch({ type: GET_INVOICE_SUCCESS, payload })
                },
                error => {
                    reject(error)
                    dispatch({ type: GET_INVOICE_FAIL, error })
                }
            )
    }
})

export const getInvoiceUnAuthed = ({ invoice_id = false, loading_only = false, reset = false }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_INVOICE })

    if (invoice_id !== false) {
        InvoiceService.getInvoiceUnAuthed({ invoice_id })
            .then(
                payload => {
                    resolve(payload)
                    dispatch({ type: GET_INVOICE_SUCCESS, payload })
                },
                error => {
                    reject(error)
                    dispatch({ type: GET_INVOICE_FAIL, error })
                }
            )
    } else {
        reject('missing-invoice-id')
    }
})

export const doInvoiceExport = (search) => async (dispatch) => {
    dispatch({ type: DO_INVOICE_EXPORT })

    InvoiceService.doInvoiceExport(search)
        .then(
            payload => dispatch({ type: DO_INVOICE_EXPORT_SUCCESS, payload }),
            error => dispatch({ type: DO_INVOICE_EXPORT_FAIL, error })
        )
}

export const updateInvoice = ({ invoice }) => async (dispatch) => {
    dispatch({ type: UPDATE_INVOICE, invoice })
}

export const processInvoice = ({ invoice }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch(updateInvoice({ invoice: { ...invoice, is_paying_processing: true } }))

    InvoiceService.processInvoice(invoice)
        .then(
            success => {
                invoice.status_name = success.status_name
                resolve(success)
            },
            error => reject(error)
        )
        .finally(() => {
            invoice.is_paying_processing = false

            dispatch(updateInvoice({ invoice }))
        })
})

// TODO:: rename this to make it more global.
export const settingsFormGet = ({
                                    loading_only = false,
                                    action = undefined,
                                    scope = 'account-settings-form',
                                    ga_client_id = null,
                                    ga_session_id = null,
                                } = {}) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: USER_SETTINGS_GET })

    if (loading_only === true) {
        resolve()
        return true
    }

    UserService.getSettings({
        scope,
        action,
        ga_client_id,
        ga_session_id
    }).then(payload => {
        dispatch({ type: USER_SETTINGS_GET_SUCCESS, payload })
        resolve(payload)
    })
        .catch(payload => {
            reject(payload)
            dispatch({ type: USER_SETTINGS_GET_FAIL, payload })
        })
})

export const settingsFormSave = (settings) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: USER_SETTINGS_SAVE, settings })

    // TODO::after the api is up we we can uncomment this
    UserService.saveSettings(settings).then(payload => {
        dispatch({ type: USER_SETTINGS_SAVE_SUCCESS, payload })

        resolve(payload)
    }).catch(payload => {
        dispatch({ type: USER_SETTINGS_SAVE_FAIL, payload })
        reject()
    })
})

export const getUserPreferences = ({
    action = undefined,
    scope = 'preferences',
} = {}) => async (dispatch) => {

    return UserService.getSettings({ scope, action })
}


export const saveUserPreferences = (params) => async (dispatch) => {
    return UserService.saveUserPreferences(params)
}

export const processForgotPasswordRequest = (email) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: FORGOT_PASSWORD })

    UserService.processForgotPassword({ email }).then(
        payload => {
            dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload })
            resolve(payload)
        },
        error => {
            dispatch({ type: FORGOT_PASSWORD_FAIL, error })
            reject(error)
        }
    )
})

export const processForgotPasswordChange = (password, password_confirm, { l }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: FORGOT_PASSWORD_CHANGE })

    UserService.processForgotPasswordChange(password, password_confirm, { l }).then(
        payload => {
            resolve(payload)
            dispatch({ type: FORGOT_PASSWORD_CHANGE_SUCCESS, payload })
        },
        error => {
            resolve(reject)
            dispatch({ type: FORGOT_PASSWORD_CHANGE_FAIL, error })
        }
    )
})

export const settingsFormInputChanged = ({ target }, isLoading = false) => async (dispatch, getStore) => {
    const { name, value, resetAddress = false } = target

    if (!isLoading) {
        if (name == 'country_id' || resetAddress) {
            dispatch({ type: SETTINGS_INPUT_CHANGED, name: 'address', value: '' })
            dispatch(settingsFormInputChanged({ target: { name: 'city', value: '' } }))
            dispatch(settingsFormInputChanged({ target: { name: 'zipcode', value: '' } }))
            dispatch(settingsFormInputChanged({ target: { name: 'state_id', value: '' } }))
            dispatch(settingsFormInputChanged({ target: { name: 'timezone_id', value: '' } }))
        }

        if (name == 'address' && target.option) {
            dispatch(settingsFormInputChanged({ target: { name: 'address', value } }))

            if (value != '') {
                dispatch(settingsFormInputChanged({ target: { name: 'city', value: target.option.city } }))
                dispatch(settingsFormInputChanged({ target: { name: 'zipcode', value: target.option.zipcode } }))
                dispatch(settingsFormInputChanged({ target: { name: 'state_id', value: target.state_data.state_id } }))
            }

            return true
        } else if (name == "state_id" && target.timezone) {// state has been updated, need to update timezone without jQuery

            dispatch({ type: SETTINGS_INPUT_CHANGED, name, value }) //save state in redux
            dispatch(settingsFormInputChanged({ target: { name: 'timezone_id', value: target.timezone } }))


            setTimeout(() => {
                window.jQuery(`[name='timezone_id']`).parsley && window.jQuery(`[name='timezone_id']`).parsley().validate()
            }, 100)

            return true
        } else if (name == 'timezone_id' && isNaN(value)) {
            const store = getStore()
            const selected_timezone = get(store.address, 'timezones', []).find(({ timezone = 0 }) => timezone == value) || { timezone_id: 0 }

            dispatch({ type: SETTINGS_INPUT_CHANGED, name, value: selected_timezone.timezone_id })
            return true
        }
    }

    dispatch({ type: SETTINGS_INPUT_CHANGED, name, value })
}

export const settingsFormClear = () => async (dispatch) => {
    dispatch({ type: SETTINGS_FORM_CLEAR })
}

export const persistLoginState = ({ user_id, payment_method, email, timezone, reg_type, has_imports, show = 'closed', position = 'left', tab = 'tab-thinamabob-user' }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: USER_PERSIST_LOGIN, user_id, payment_method, email, timezone, reg_type, has_imports })

    dispatch({ type: THINGAMABOB_SET_PROP, name: 'show', value: show })
    dispatch({ type: THINGAMABOB_SET_PROP, name: 'position', value: position })
    dispatch({ type: THINGAMABOB_SET_PROP, name: 'tab', value: tab })

    resolve()
})

export const doLogin = ({ reg_type, user_id, email, suspended, suspended_reason_id, payment_method, has_imports, timezone }) => async (dispatch) => {
    dispatch({ type: USER_LOGIN, reg_type, user_id, email, suspended, suspended_reason_id, payment_method, has_imports, timezone })
}

export const doLogout = () => async (dispatch) => {
    dispatch({ type: USER_LOGOUT })
}

export const getWonAuctions = ({ term = '', page_num = 0, page_count = 30, sort_column = 'close_date', sort_direction = "desc" }) => async (dispatch) => {
    dispatch({ type: GET_WON_AUCTIONS })

    UserService.getWonAuctions({ term, page_num, page_count, sort_column, sort_direction })
        .then(payload => {
            dispatch({ type: GET_WON_AUCTIONS_SUCCESS, payload })
        })
        .catch(payload => {
            dispatch({ type: GET_WON_AUCTIONS_FAIL, payload })
        })
}

export const setFormatTableData = (table_data) => async (dispatch) => {
    dispatch({ type: SET_FORMATTED_TABLE_DATA, table_data })
}

export const loadPaymentToken = () => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: LOAD_PAYMENT_TOKEN })

    PaymentGatewayService.getToken().then(
        payload => {
            dispatch({ type: LOAD_PAYMENT_TOKEN_SUCCESS, payload })
            resolve(payload)
        },
        error => {
            dispatch({ type: LOAD_PAYMENT_TOKEN_FAIL, error })
            reject(error)
        }
    )
})

export const auctionDetailToggleAuctionWatch = ({ auction_id, add_watch = false, watchlist_id = null }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: AUCTION_DETAIL_AUCTION_WATCH_TOGGLE })
    UserService.toggleDetailAuctionWatch({ auction_id, add_watch })
        .then(
            payload => {
                dispatch({ type: AUCTION_DETAIL_AUCTION_WATCH_TOGGLE_SUCCESS, payload })
                resolve()
            },
            error => {
                dispatch({ type: AUCTION_DETAIL_AUCTION_WATCH_TOGGLE_FAIL, error })
                reject()
            }
        )
})

export const upgradeFormInputChanged = (event) => async (dispatch) => {
    dispatch({ type: UPGRADE_INPUT_FORM_CHANGE, name: event.target.name, value: event.target.value })
}

export const upgradeFormClear = () => async (dispatch) => {
    dispatch({ type: UPGRADE_FORM_CLEAR })
}

export const changeMembership = ({ action, interval_length = 'monthly', promo_code = '' }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: CHANGE_MEMBERSHIP })

    UserService.changeMembership({ action, interval_length, promo_code })
        .then(payload => {
            dispatch({ type: CHANGE_MEMBERSHIP_SUCCESS, payload: payload })
            resolve(payload)
        })
        .catch(payload => {
            dispatch({ type: CHANGE_MEMBERSHIP_FAIL, payload: payload })
            reject(payload)
        })
})

export const bidEntered = ({ auction_id, max_bid }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: AUCTION_BID_SUBMIT })

    const ga_session_id = Cookies.get('_ga_YM2Y56PMJX')

    UserService.submitBid({ auction_id, max_bid, ga_session_id })
        .then(
            payload => dispatch({ type: AUCTION_BID_SUBMIT_SUCCESS, payload, resolve, reject }),
            error => dispatch({ type: AUCTION_BID_SUBMIT_FAIL, error, resolve, reject })
        )
})

export const bidBanner = (notification = false) => async (dispatch) => {
    dispatch({ type: USER_BID_BANNER, notification })
}

export const validatePromoCode = ({ promo_code }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: USER_CHECKING_PROMO_CODE })

    // If no types promo code it's valid
    if (promo_code.length == 0) {
        resolve({ status: true })
        dispatch({ type: USER_CHECKING_PROMO_CODE_SUCCESS, payload: { status: true } })
        return false
    }
    UserService.validatePromoCode({ promo_code })
        .then(
            payload => {
                resolve(payload)
                dispatch({ type: USER_CHECKING_PROMO_CODE_SUCCESS, payload })
            },
            error => {
                reject(error)
                dispatch({ type: USER_CHECKING_PROMO_CODE_FAIL, error })
            }
        )
})

export const getFMActiveAuctions = (search = '', options = {}) => async (dispatch) => {
    dispatch({ type: GET_FM_ACTIVE_AUCTIONS })

    UserService.getFMActiveAuctions(search, options)
        .then(res => {
            dispatch({ type: GET_FM_ACTIVE_AUCTIONS_SUCCESS, payload: res })
        })
        .catch(error => {
            dispatch({ type: GET_FM_ACTIVE_AUCTIONS_FAIL, error })
        })
}

export const activeAuctionsInputChanged = (target) => async (dispatch) => {
    const { name, value } = target

    dispatch({ type: ACTIVE_AUCTIONS_INPUT_CHANGED, name, value })
}

export const activeSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: ACTIVE_SEARCH_INPUT_CHANGED, key, value })
}

export const getFMSoldAuctions = (search = '', options = {}) => async (dispatch) => {
    dispatch({ type: GET_FM_SOLD_AUCTIONS })

    UserService.getFMSoldAuctions(search, options)
        .then(res => {
            dispatch({ type: GET_FM_SOLD_AUCTIONS_SUCCESS, payload: res })
        })
        .catch(error => {
            dispatch({ type: GET_FM_SOLD_AUCTIONS_FAIL, error })
        })
}

export const soldAuctionsInputChanged = (target) => async (dispatch, getStore) => {
    const { name, value } = target

    dispatch({ type: SOLD_AUCTIONS_INPUT_CHANGED, name, value })
}

export const soldSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: SOLD_SEARCH_INPUT_CHANGED, key, value })
}

export const getFMUnsoldAuctions = (search = '', options = {}) => async (dispatch) => {
    dispatch({ type: GET_FM_UNSOLD_AUCTIONS })

    UserService.getFMUnsoldAuctions(search, options)
        .then(res => {
            dispatch({ type: GET_FM_UNSOLD_AUCTIONS_SUCCESS, payload: res })
        })
        .catch(error => {
            dispatch({ type: GET_FM_UNSOLD_AUCTIONS_FAIL, error })
        })
}

export const unsoldAuctionsInputChanged = (target) => async (dispatch, getStore) => {
    const { name, value } = target

    dispatch({ type: UNSOLD_AUCTIONS_INPUT_CHANGED, name, value })
}

export const unsoldSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: UNSOLD_SEARCH_INPUT_CHANGED, key, value })
}

export const getFMCanceledAuctions = (search = '', options = {}) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_FM_CANCELED_AUCTIONS })

    UserService.getFMCanceledAuctions(search, options)
        .then(res => {
            dispatch({ type: GET_FM_CANCELED_AUCTIONS_SUCCESS, payload: res })
            resolve()
        })
        .catch(error => {
            dispatch({ type: GET_FM_CANCELED_AUCTIONS_FAIL, error })
            reject()
        })
})

export const canceledAuctionsInputChanged = (target) => async (dispatch, getStore) => {
    const { name, value } = target

    dispatch({ type: CANCELED_AUCTIONS_INPUT_CHANGED, name, value })
}

export const canceledSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: CANCELED_SEARCH_INPUT_CHANGED, key, value })
}

export const getFacilityManagerMetrics = () => async (dispatch) => {
    dispatch({ type: FACILITY_MANAGER_METRICS })

    UserService.fmDashboardMetrics().then(
        payload => dispatch({ type: FACILITY_MANAGER_METRICS_SUCCESS, payload }),
        error => dispatch({ type: FACILITY_MANAGER_METRICS_FAIL, error })
    )
}

export const fmGetAuction = (
    {
        auction_id = false,
        draft_id = false,
        import_id = false,
        relist_id = false,
        date_formatting = true,
        auction_status = 'active',
        triggerLoading = false,
        scope = false,
        withCleanup = false,
        trigger_reloading = true,
        facility_id = undefined,
        import_unit_id = undefined,
    }
) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: FACILITY_MANAGER_GET_AUCTION_DETAILS, trigger_reloading })

    if (triggerLoading === true) {
        resolve()
        return true
    }

    AuctionService.fmGetAuction({
        auction_id,
        draft_id,
        import_id,
        relist_id,
        auction_status,
        scope,
        facility_id,
        import_unit_id
    })
        .then(
            payload => {
                resolve(payload)
                dispatch({
                    type: FACILITY_MANAGER_GET_AUCTION_DETAILS_SUCCESS,
                    payload,
                    date_formatting,
                    withCleanup,
                    auction_id,
                    draft_id,
                    import_id,
                    relist_id
                })
            },
            error => {
                reject(error)
                dispatch({
                    type: FACILITY_MANAGER_GET_AUCTION_DETAILS_FAIL,
                    error
                })
            }
        )
})

export const fmGetLiveAuction = ({ live_auction_id }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS })

    LiveAuctionService.fmGetLiveAuction({ live_auction_id }).then(
        payload => {
            resolve(payload)
            dispatch({ type: FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_SUCCESS, payload })
        },
        error => {
            reject(error)
            dispatch({ type: FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_FAIL, error })
        }
    )
})

export const liveAuctionFormUpdate = ({ target = {}, clear_all = false }) => async (dispatch) => {
    const { name, value } = target

    dispatch({ type: FACILITY_MANAGER_LIVE_AUCTION_FORM_UPDATE, name, value, clear_all })
}

export const liveAuctionSave = ({ form_data, live_auction_id = false }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE })

    LiveAuctionService.saveAuction({ form_data, live_auction_id }).then(
        success => {
            resolve(success)
            dispatch({ type: FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_SUCCESS })
        },
        error => {
            reject(error)
            dispatch({ type: FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_FAIL })
        }
    )

})

export const auctionPostingFormClear = () => ({
    dispatch: { type: AUCTION_POSTING_FORM_CLEAR }
})

/**
 * @param {string} file_type
 * @param {int} auction_id
 * @param {int} draft_id
 * @param {File} file
 * @returns {function(*): *}}
 */
export const auctionPreSignedUrl = ({ file_type, auction_id, draft_id, file }) => async (dispatch) => new Promise((resolve, reject) => {
    AuctionService.requestPreSignedUrl({ file_type, auction_id, draft_id, file }).then(resolve, reject)
})

export const auctionPostingFormInput = ({ target }) => async (dispatch) => {
    const { name, value, action = false } = target

    if (action == false) {
        return dispatch({ type: AUCTION_POSTING_INPUT, name, value })
    }

    // clear form values
    dispatch({ type: AUCTION_POSTING_INPUT, clear_all: true })
}

export const facilityEditFormData = (target) => async (dispatch) => {
    const { name = false, value = false, clearForm = false, resetAddress = false } = target

    if (name == 'country_id' || resetAddress) {
        dispatch({ type: SET_FACILITY_EDIT_FORM_DATA, name: 'address', value: '', clearForm })
        dispatch({ type: SET_FACILITY_EDIT_FORM_DATA, name: 'city', value: '', clearForm })
        dispatch({ type: SET_FACILITY_EDIT_FORM_DATA, name: 'zipcode', value: '', clearForm })
        dispatch({ type: SET_FACILITY_EDIT_FORM_DATA, name: 'state_id', value: '', clearForm })
        dispatch({ type: SET_FACILITY_EDIT_FORM_DATA, name: 'timezone_id', value: '', clearForm })
    }

    if (target.setCanadianFieldValues) {
        const { city, zipcode } = target[0].option

        dispatch(facilityEditFormData({ name: 'city', value: city }))
        dispatch(facilityEditFormData({ name: 'zipcode', value: zipcode }))
        dispatch(facilityEditFormData({ name: 'state_id', value: target.state_data.state_id }))

        const fields = ['city', 'zipcode', 'state_id']
        fields.forEach(field => {
            if (window.jQuery(`[name='${field}']`).length > 0) {
                setTimeout(() => {
                    window.jQuery(`[name='${field}']`).parsley && window.jQuery(`[name='${field}']`).parsley().validate()
                }, 100)
            }
        })
    }

    if (name == 'address' && target.option && target.countryId != '37') {
        dispatch({ type: SET_FACILITY_EDIT_FORM_DATA, name, value: target.option.street, clearForm })

        if (value != '') {
            dispatch(facilityEditFormData({ name: 'city', value: target.option.city }))
            dispatch(facilityEditFormData({ name: 'zipcode', value: target.option.zipcode }))
            dispatch(facilityEditFormData({ name: 'state_id', value: target.state_data.state_id }))
        }

        return true
    }

    dispatch({ type: SET_FACILITY_EDIT_FORM_DATA, name, value, clearForm })
}

export const facilityEditFormSubmit = ({ form_data, facility_id }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: FACILITY_SAVE })

    FacilityService.fmSaveFacility({ form_data, facility_id }).then(
        payload => {
            resolve(payload)
            dispatch({ type: FACILITY_SAVE_SUCCESS, payload })
        },
        error => {
            reject(error)
            dispatch({ type: FACILITY_SAVE_FAIL, error })
        }
    )
})

export const submitAuctionDetails = (form_values) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: AUCTION_SAVE_DETAILS })

    AuctionService.saveAuctionDetails(form_values).then(
        payload => {
            resolve(payload)
            dispatch({ type: AUCTION_SAVE_DETAILS_SUCCESS, payload })
        },
        error => {
            reject(error)
            dispatch({ type: AUCTION_SAVE_DETAILS_FAIL, error })
        }
    )
})

export const getFMCanceledAfterSoldAuctions = (search = '', options = {}) => async (dispatch) => {
    dispatch({ type: GET_FM_CANCELED_AFTER_SOLD_AUCTIONS })

    UserService.getFMCanceledAfterSoldAuctions(search, options)
        .then(res => {
            dispatch({ type: GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_SUCCESS, payload: res })
        })
        .catch(error => {
            dispatch({ type: GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_FAIL, error })
        })
}

export const canceledAfterSoldAuctionsInputChanged = (target) => async (dispatch, getStore) => {
    const { name, value } = target

    dispatch({ type: CANCELED_AFTER_SOLD_AUCTIONS_INPUT_CHANGED, name, value })
}

export const getAccountDefaults = () => async (dispatch) => new Promise((resolve, reject) => {
    UserService.getAccountDefaults().then(resolve, reject)
})

export const canceledAfterSoldSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: CANCELED_AFTER_SOLD_SEARCH_INPUT_CHANGED, key, value })
}

export const getFMDraftAuctions = (search = '', options = {}) => async (dispatch) => {
    dispatch({ type: GET_FM_DRAFT_AUCTIONS })

    UserService.getFMDraftAuctions(search, options)
        .then(res => {
            dispatch({ type: GET_FM_DRAFT_AUCTIONS_SUCCESS, payload: res })
        })
        .catch(error => {
            dispatch({ type: GET_FM_DRAFT_AUCTIONS_FAIL, error })
        })
}

export const draftAuctionsInputChanged = (target) => async (dispatch, getStore) => {
    const { name, value } = target

    dispatch({ type: DRAFT_AUCTIONS_INPUT_CHANGED, name, value })
}

export const draftSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: DRAFT_SEARCH_INPUT_CHANGED, key, value })
}

export const deleteDraftAuction = (draft_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: DELETE_DRAFT_AUCTION })

    UserService.deleteDraftAuction(draft_id)
        .then(data => {
            dispatch({ type: DELETE_DRAFT_AUCTION_SUCCESS, payload: data })
            resolve()
        })
        .catch(response => {
            dispatch({ type: DELETE_DRAFT_AUCTION_FAIL, payload: response })
            reject()
        })
})

export const getFMImportedAuctions = (search = '', options = {}) => ({
    pre_type: GET_FM_IMPORTED_AUCTIONS,

    async_service_call: UserService.getFMImportedAuctions(search, options),

    success: GET_FM_IMPORTED_AUCTIONS_SUCCESS,
    failed: GET_FM_IMPORTED_AUCTIONS_FAIL,
})

export const importedSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: IMPORTED_SEARCH_INPUT_CHANGED, key, value })
}

export const getFMFacilities = (search = '', options = {}) => async (dispatch) => {
    dispatch({ type: GET_FM_FACILITIES })

    UserService.getFMFacilities(search, options)
        .then(res => {
            dispatch({ type: GET_FM_FACILITIES_SUCCESS, payload: res })
        })
        .catch(error => {
            dispatch({ type: GET_FM_FACILITIES_FAIL, error })
        })
}


export const facilitiesSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: FACILITIES_SEARCH_INPUT_CHANGED, key, value })
}

export const getFacilityFeatures = () => ({
    pre_type: FACILITY_LOAD_FEATURES,

    async_service_call: FacilityService.getFacilityFeatures(),

    success: FACILITY_LOAD_FEATURES_SUCCESS,
    failed: FACILITY_LOAD_FEATURES_FAIL,
})

export const saveLienFile = ({ auction_id = false, draft_id = false, file }) => async (dispatch) => new Promise((resolve, reject) => {
    AuctionFileService.postLienFile({ auction_id, draft_id, file }).then(
        success => resolve(success),
        fail => reject(fail)
    )
})

export const deleteLienFile = ({ auction_id = false, draft_id = false, auction_status, file_id }) => async (dispatch) => new Promise((resolve, reject) => {
    FacilityService.deleteLienFile({ auction_id, draft_id, auction_status, file_id }).then(
        success => resolve(success),
        fail => reject(fail)
    )
})

export const postImage = ({ facility_id = false, image_type = false, file, name, call_id = undefined }) => async (dispatch) => new Promise((resolve, reject) => {
    FacilityFileService.postImage({ facility_id, image_type, file, name, call_id }).then(
        success => resolve(success),
        fail => reject(fail)
    )
})

export const deleteFacilityImage = ({ facility_id, image_type, image_id }) => async (dispatch) => new Promise((resolve, reject) => {
    FacilityService.deleteImage({ facility_id, image_type, image_id }).then(
        success => resolve(success),
        fail => reject(fail)
    )
})

export const customDateInputChanged = ({ name, value }) => async (dispatch) => {
    dispatch({ type: CUSTOM_DATE_INPUT_CHANGED, name, value })
}

export const cancelFormUpdated = ({ target: { name, value, clearAll = false } }) => async (dispatch) => {
    await dispatch({ type: AUCTION_CANCEL_FORM, name, value, clearAll })
}

export const cancelAuction = ({ auction_id, auction_status, cancelation_reason_id, cancelation_reason_other }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: AUCTION_CANCEL })

    AuctionService.cancelAuction({ auction_id, auction_status, cancelation_reason_id, cancelation_reason_other }).then(
        payload => {
            resolve(payload)
            dispatch({ type: AUCTION_CANCEL_SUCCESS, payload })
        },
        fail => {
            reject(fail)
            dispatch({ type: AUCTION_CANCEL_FAIL, fail })
        }
    )
})

export const getFMLiveAuctions = (search = '', options = {}) => async (dispatch) => {
    dispatch({ type: GET_FM_LIVE_AUCTIONS })

    UserService.getFMLiveAuctions(search, options)
        .then(res => {
            dispatch({ type: GET_FM_LIVE_AUCTIONS_SUCCESS, payload: res })
        })
        .catch(error => {
            dispatch({ type: GET_FM_LIVE_AUCTIONS_FAIL, error })
        })
}

export const liveAuctionsInputChanged = (target) => async (dispatch) => {
    const { name, value } = target

    dispatch({ type: LIVE_AUCTIONS_INPUT_CHANGED, name, value })
}

export const liveSearchInputChanged = (event) => async (dispatch) => {
    // catch undefined issues
    const { name: key = false, value = '' } = event.target || {}
    if (!key) return false

    dispatch({ type: LIVE_SEARCH_INPUT_CHANGED, key, value })
}

export const cancelLiveAuction = (live_auction_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: CANCEL_LIVE_AUCTION })

    LiveAuctionService.cancelLiveAuction(live_auction_id)
        .then(payload => {
            resolve(payload)
            dispatch({ type: CANCEL_LIVE_AUCTION_SUCCESS, payload })

        })
        .catch(response => {
            reject(response)
            dispatch({ type: CANCEL_LIVE_AUCTION_FAIL, payload: response })

        })
})

/**
 *
 * @param auction_id
 * @param draft_id
 * @returns {Function}
 */
export const getAuctionImage = ({ auction_id = false, draft_id = false }) => async (dispatch) => {
    dispatch({ type: AUCTION_IMAGE_GET })
    AuctionService.getAuctionImage({ auction_id, draft_id }).then(
        payload => dispatch({ type: AUCTION_IMAGE_GET_SUCCESS, payload }),
        error => dispatch({ type: AUCTION_IMAGE_GET_FAIL, error })
    )
}

/**
 *
 * @param file
 * @param auction_id
 * @param draft_id
 * @returns {function(*=): Promise}
 */
export const mashAuctionFiles = ({
    file_type = 'images|files',
    files = [],
    auction_id = false,
    draft_id = false,
    facility_image_type = undefined,
    facility_id = undefined
}) => async (dispatch) => new Promise(async (resolve, reject) => {
    // identifier, file_type, identifier_id, file_id
    dispatch({ type: FILE_POST_RESET, payload: { uploading: true } })

    const goal_percentage = files.length * 100
    let files_uploaded = 1
    let current_percentage = 0
    let percentage_progress = 0

    const uploaded = []
    const errors = []

    for (let inc = 0; inc < files.length; inc++) {
        try {
            const file = files[inc]

            // For each file get a pre signed url from the api
            const { url = null } = await AuctionService.requestPreSignedUrl({
                // facility image stuff
                facility_image_type,
                facility_id,

                // auction image stuff
                file_type,
                auction_id,
                draft_id,
                file
            })

            if (!url) {
                throw 'Bucket Error'
            }

            const onUploadProgress = (data) => {
                const { loaded, total } = data

                current_percentage = Math.floor((loaded / total) * 100)

                const new_percentage_progress = (current_percentage * files_uploaded) / goal_percentage * 100
                if (new_percentage_progress > percentage_progress) {
                    percentage_progress = new_percentage_progress
                }

                // TODO :: we talked about shows processing 1 of total in the progress bar.

                dispatch({
                    type: FILE_POST, payload: {
                        inc,
                        percentage_progress,
                        files_updated: files_uploaded,
                        current_percentage,
                        goal_percentage,
                        file_name: file.name,
                        uploading: true
                    }
                })
            }

            const { data } = await AuctionFileService.postAuctionFiles({
                url,
                file,
                auction_id,
                draft_id,
                onUploadProgress
            })

            uploaded.push({ file, data })
            files_uploaded++
        } catch (e) {
            // TODO :: handle this error better. Append to the errors array
            errors.push(e)
            console.log('file_uploading.issue', { e })
        }
    }

    dispatch({ type: FILE_POST_RESET })
    resolve({ errors, uploaded })
})

export const postIdentityImage = ({
    user_id,
    file,
}) => async () => new Promise(async (resolve, reject) => {
    const errors = []

    try {
        const { url = null } = await UserService.requestPreSignedUrl({ user_id, file })

        if (!url) {
            throw 'Bucket Error'
        }

        const { data } = await IdImageService.postImage({
            url,
            file,
        })

    } catch (e) {
        errors.push(e)
        console.log('file_uploading.issue', { e })
    }

    resolve({ errors })
})

/**
 *
 * @param file full image object from the api
 * @param auction_id int
 * @param draft_id int
 * @param action could be rotate-left, rotate-right, trash
 * @param direction
 * @param auction_status
 * @param image_ids
 * @returns {function(*=): Promise}
 */
export const editAuctionImage = ({ file, auction_id = false, draft_id = false, action, direction = false, auction_status, image_ids = [] }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: AUCTION_IMAGE_EDIT })
    AuctionService.editAuctionImage({ file, auction_id, draft_id, action, auction_status, image_ids, direction }).then(
        payload => {
            dispatch({ type: AUCTION_IMAGE_EDIT_SUCCESS, payload })
            resolve(payload)
        },
        error => {
            dispatch({ type: AUCTION_IMAGE_EDIT_FAIL, error })
            reject(error)
        }
    )
})

export const saveAuctionAsDraft = (draft_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: SAVE_AUCTION_AS_DRAFT })
    AuctionService.saveAuctionAsDraft(draft_id).then(
        payload => {
            dispatch({ type: SAVE_AUCTION_AS_DRAFT_SUCCESS, payload })
            resolve(payload)
        },
        error => {
            dispatch({ type: SAVE_AUCTION_AS_DRAFT_FAIL, error })
            reject(error)
        }
    )
})

export const postAuction = (auction_id) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch(formSavingStart())
    dispatch({ type: POST_AUCTION })

    AuctionService.postAuction(auction_id)
        .then(
            payload => {
                dispatch({ type: POST_AUCTION_SUCCESS, payload })
                resolve(payload.auction_id)
                dispatch(formSavingDone())
            },
            error => {
                dispatch({ type: POST_AUCTION_FAIL, error })
                reject(error)
                dispatch(formSavingDone())
            }
        )
})

export const createdPaymentProfile = ({ action = undefined, reload_settings_form = true } = {}) => async (dispatch) => new Promise((resolve, reject) => {
    UserService.paymentProfile({ action }).then(
        (success) => {
            const {
                payment_method = 1,
                payment_profile = <span><i className="fa fa-spin fa-spinner" /> Updating</span>,
                authnet_cppid = undefined
            } = success

            dispatch({ type: USER_SETTINGS_PAYMENT_METHOD, payment_method, payment_profile, authnet_cppid })

            reload_settings_form && dispatch(settingsFormGet())
            resolve(success)
        },
        (error) => {
            reload_settings_form && dispatch(settingsFormGet())
            reject(error)
        }
    )
})

export const formSavingStart = ({ auto_done = true, auto_done_timeout = 100 } = {}) => async (dispatch) => {
    dispatch({ type: FORM_SAVING_START })

    if (auto_done === true) {
        setTimeout(() => dispatch(formSavingDone()), auto_done_timeout)
    }
}

export const formSavingDone = () => async (dispatch) => {
    dispatch({ type: FORM_SAVING_DONE })
}

export const getReports = (term = '', { trigger_loading = true, callback, ...search_obj } = {}) => async (dispatch) => {

    trigger_loading && dispatch({ type: GET_REPORTS })

    ReportsService.getReports(search_obj).then(
        payload => {
            callback && callback(payload)
            return dispatch({ type: GET_REPORTS_SUCCESS, payload })
        },
        error => dispatch({ type: GET_REPORTS_FAIL, error })
    )
}

export const deleteReport = (report_id) => async (dispatch) => new Promise((resolve, reject) => {
    ReportsService.deleteReport(report_id)
        .then(success => resolve(success))
        .catch(error => reject(error))
})

export const requestReport = (report_type, search_obj) => async (dispatch) => new Promise((resolve, reject) => {
    ReportsService.requestReports(report_type, search_obj)
        .then(payload => resolve(payload))
        .catch(error => reject(error))
})

export const actionsActivateUser = ({ l }) => async (dispatch) => new Promise((resolve, reject) => {
    UserService.actionsActiveUser({ l }).then(
        success => resolve(success),
        failed => reject(failed)
    )
})

export const seoSET = ({ og }) => ({
    dispatch: { type: SEO_SET, og }
})

export const seoReset = () => ({
    dispatch: { type: SEO_CLEAR }
})

export const setAsRoute = (asPath = '/test') => ({
    dispatch: { type: PAGE_AS_ROUTE, asPath }
})

export const requestFormCSRF = ({ scope = undefined }) => async (dispatch) => new Promise((resolve, reject) => {
    FormService.requestToken({ scope })
        .then(({ token: csrf_token }) => {
                resolve({ csrf_token })
                dispatch({ type: FORM_REQUEST_CSRF_TOKEN, csrf_token })
            },
            err => reject(err)
        )
})

export const getCases = ({ params } = {}) => async (dispatch) => {
    return await CaseService.index(params)
}

export const showCase = ({ case_id }) => async (dispatch) => {
    return CaseService.show({ case_id })
}

export const cancelCase = (params) => async (dispatch) => (
    CaseService.cancel(params)
)

export const saveCases = ({ case_id = false, params }) => async (dispatch) => {
    if (case_id) {
        return CaseService.update({ case_id, params })
    }

    return CaseService.create({ params })
}

export const getOffers = (params) => async (dispatch) => {
    return OfferService.index(params)
}

export const getOffer = (offer_id) => async (dispatch) => new Promise((resolve, reject) => {
    if (offer_id) {
        dispatch({ type: GET_OFFER })
    }

    OfferService.get(offer_id)
        .then(data => {
            dispatch({ type: GET_OFFER_SUCCESS, payload: data })

            resolve(data)
        })
        .catch(response => {
            dispatch({ type: GET_OFFER_FAIL, error: response })

            reject(response)
        })
})

export const updateOffer = ({ offer_id, case_id, approval }) => async (dispatch) => new Promise((resolve, reject) => {
    if (offer_id) {
        dispatch({ type: UPDATE_OFFER })
    }

    OfferService.update(offer_id, case_id, approval).then(data => {
        // dispatch({ type: UPDATE_OFFER_SUCCESS, payload: data })

        resolve(data)
    }).catch(response => {
        dispatch({ type: UPDATE_OFFER_FAIL, error: response })

        reject(response)
    })
})

export const getExpiresIn = ({ value }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: EXPIRES_IN, value })

    return resolve(value)
})

export const getUserPermissions = ({ scope = 'permissions' }) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_PERMISSIONS })

    UserService.getUserPermissions({ scope })
        .then((res) => {
            dispatch({ type: GET_USER_PERMISSIONS_SUCCESS, payload: res })
            resolve(res)
        })
        .catch((error) => {
            dispatch({ type: GET_USER_PERMISSIONS_FAIL, error })
            reject(error)
        })
})

export const getIDVerificationQuestions = (params) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_ID_VERIFICATION_QUESTIONS })

    UserService.getUserIDVerificationQuestions(params)
        .then((res) => {
            dispatch({ type: GET_ID_VERIFICATION_QUESTIONS_SUCCESS, payload: res })
            resolve(res)
        })
        .catch((error) => {
            dispatch({ type: GET_ID_VERIFICATION_QUESTIONS_FAIL, error })
            reject(error)
        })
})

export const setNavigatingToAuction = (navigating) => (dispatch) => {
    dispatch({
        type: SET_NAVIGATING_TO_AUCTION,
        payload: navigating
    })
}

export const getPickupPersons = (params) => async (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: GET_USER_PICKUP_PERSONS })

    UserService.getPickupPersons(params)
        .then((res) => {
            dispatch({ type: GET_USER_PICKUP_PERSONS_SUCCESS, payload: res })
            resolve(res)
        })
        .catch((error) => {
            dispatch({ type: GET_USER_PICKUP_PERSONS_FAIL, error })
            reject(error)
        })
})
