import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { waitForElement } from '../../../helpers/page'
import './styles.css'

const Carousel = ({
    timeout,
    name,
    showControls = true,
    showIndicators = true,
    controlsColor= '',
    height,
    minHeight,
    className,
    innerClassName,
    accentColor,
    initialSlide,
    children
}) => {
    const items = Array.isArray(children) ? children : []

    useEffect(() => {
        const elementQuery = `#${name}.carousel`

        waitForElement(elementQuery).then(({ element }) => {
            window.$(element).carousel(timeout ? { interval: timeout } : { pause: true })
        })
    }, [name, timeout])

    const renderControls = () => {
        if (!showControls) {
            return null
        }

        return (
            <>
                <a className={`left carousel-control ${'accentColor'}`} href={`#${name}`} data-slide="prev">
                    <span className="control left glyphicon glyphicon-chevron-left fa fa-chevron-left" style={{ color: controlsColor }} />
                </a>
                <a className={`right carousel-control ${'accentColor'}`} href={`#${name}`} data-slide="next" style={{ color: controlsColor }}>
                    <span className="control right glyphicon glyphicon-chevron-right fa fa-chevron-right" />
                </a>
            </>
        )
    }

    const renderIndicators = () => {
        const slide = get({ initialSlide }, 'initialSlide', 0)
        return items.map((item, index) => (
            <li data-target={`#${name}`} data-slide-to={index} className={accentColor + (index === slide ? ' active' : '')} key={index} />
        ))
    }

    const renderItems = () => {
        const slide = get({ initialSlide }, 'initialSlide', 0)
        return items.map((item, index) => {
            const itemClass = `item ${index === slide ? ' active' : ''} ${item?.props?.className}`
            const style = {}

            if (height) {
                style.height = `${height}px !important`
            }

            if (minHeight) {
                style.minHeight = `${minHeight}px !important`
            }
            return (
                <div className={itemClass} key={index} style={style}>
                    {item?.props?.children}
                </div>
            )
        })
    }

    const carouselProps = {
        className: `carousel slide ${className}`,
        'data-ride': 'carousel',
        id: name
    }

    if (timeout !== false) {
        carouselProps['data-pause'] = 'true'
    } else {
        carouselProps['data-interval'] = timeout
    }

    return (
        <>
            <div {...carouselProps}>
                {renderControls()}
                <div className={`carousel-inner mt-3 ${innerClassName}`}>
                    {renderItems()}
                </div>
                {showIndicators && (
                    <ol className="carousel-indicators">
                        {renderIndicators()}
                    </ol>
                )}
            </div>
        </>
    )
}

Carousel.defaultProps = {
    timeout: 7000,
    children: [],
    name: 'myCarousel',
    showControls: true,
    height: 'unset',
    className: 'default',
    innerClassName: '',
    accentColor: '',
    initialSlide: 0,
    class: '',
    minHeight: null
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(Carousel)
