import { useState } from 'react'
import { GET } from '../services/RequestMethods'
import { environment } from '../services/environment'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import store from 'utils/reduxStore';
import { logout } from '../actions/AuthActions';
import { doLogout } from '../actions';
import Cookies from 'js-cookie';

export const requestCancelToken = () => {
    const source = axios.CancelToken.source()
    const cancelToken = source.token
    const cancelCallback = source.cancel

    return {
        cancelToken,
        cancelCallback
    }
}

const useService = ({
    url,
    method = GET,
    params = undefined,
    defaultData = {},
    debug = false,
    headers = {},
    baseURL = environment.apiEndPoint,
    hasAuthedUrl = false,
    formatResponseCallback,
    entityResultsKey = 'results',
    passthrough = false
} = {}) => {
    const [data, setData] = useState(defaultData)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = store().dispatch;
    const formatParams = (params, _method = method) => ({
        [_method == GET ? 'params' : 'data']: params
    })
    if(url?.includes('users/account') || url?.includes('authed/')) {
        const stRegType = Cookies.get('st.reg_type');
        if(stRegType) {
            headers['st-reg-type'] = stRegType;
        }
    }

    const [config, setConfig] = useState({
        url,
        baseURL,
        method,
        headers: {
            ...{
                'x-api-key': environment.apiKey,
                'content-type': 'application/json'
            },
            // Merge headers passed in with the presets
            ...headers
        },
        ...formatParams(params)
    })

    const getBearerToken = async () => {
        try {
            const { idToken: { jwtToken = undefined } = {} } = (await Auth.currentSession()) || {}

            return jwtToken
        } catch (e) {
        }
    }

    const fire = async ({
        params: request_params = false,
        persist_changes = true,
        hasAuthedUrl: _hasAuthedUrl = hasAuthedUrl,
        cancelToken,
        hideShowLoading = false,
        ...config_override
    } = {}) => {
        let _configs = config
        const jwtToken = await getBearerToken()

        if (jwtToken) {
            _configs.headers['Authorization'] = `Bearer ${jwtToken}`
        }

        if (config_override) {
            _configs = { ..._configs, ...config_override }
            persist_changes && setConfig(_configs)
        }

        if (request_params) {
            _configs = {
                ..._configs,
                ...formatParams(request_params, _configs.method)
            }
            persist_changes && setConfig(_configs)
        }

        if (_hasAuthedUrl && _configs.headers['Authorization']) {
            _configs.url = `authed/${config.url}`
        }
        if(_configs?.url?.includes('users/account') || _configs?.url?.includes('authed/')) {
            const stRegType = Cookies.get('st.reg_type');
            if(stRegType) {
                _configs.headers['st-reg-type'] = stRegType;
            }
        }
        setError(false)
        if (!hideShowLoading) {
            setLoading(true)
        }

        debug && console.info('useService.fire', { _configs })

        try {
            if (cancelToken) {
                _configs.cancelToken = cancelToken
            }

            let data = 'missing'
            if (passthrough) {
                data = passthrough
            } else {
                const { data: responseData = 'missing' } = await axios(_configs)
                data = responseData
            }
            debug && console.info('useService.fired.resolve', { data })

            // formatResponseCallback and entityResultsKey can handle cases where the API is responding with
            // the name of the data entity and TOTAL_RECORDS rather than the new standardized RESULTS and NUM_RESULTS
            // that Maximo recently implemented
            if (formatResponseCallback && entityResultsKey) {
                data[entityResultsKey] = formatResponseCallback(
                    data[entityResultsKey]
                )
            }

            setData(data)
            setLoading(false)

            return data
        } catch (err) {
            debug && console.info('useService.fired.reject', { err })
            setError(err)
            if (err.response?.status === 401) {
                try {
                    dispatch(logout());
                    dispatch(doLogout());
                } catch (logoutError) {
                    console.error('Error during logout:', logoutError);
                }
            }
            if (axios.isCancel(error) && error.message == 'canceled-new-call') {
                // If the cancel was triggered and its canceled
                // because of a new call then don't do anything but I'm leaving this h
            } else {
                setLoading(false)
            }

            throw err
        }
    }

    return [{ loading, data, error, setData }, fire]
}

export default useService
