import {
    CLEAR_ALERT,
    FACILITY_BOOKMARK_ADDED,
    FACILITY_BOOKMARK_REMOVED,
    PAGE_AS_ROUTE,
    SCREEN_RESIZE,
    SEO_CLEAR,
    SEO_SET,
    SET_DASHBOARD_PAGE,
    SET_GLOBAL_ALERT,
    SET_PAGE,
    SET_PAGE_CONTAINER_CLASS
} from 'actions/PageTypes'
import { get } from 'lodash'
import { loginLink } from 'helpers/page'

export const INITIAL_STATE = {
    og: {},

    loading: false,
    error: null,

    title: '',
    h1: '',
    description: '',

    data: {
        loginLinkRedirect: false,
        asPath: '/',
        title: '', // page title
        description: '',
        pageAction: '',
        dashboard_title: 'My Dashboard',
        dashboard_menu_item: 'Dashboard',
        dashboard_menu_enum: 1,
        dashboard_menu_items: ['',
            'Dashboard', // enum id 1
            'Account Settings', // enum id 2
            'Saved Searches', // enum id 3
            'AuctionWatch', // enum id 4
            'Bid Manager', // enum id 5
            'Bookmarked Facilities', // enum id 6
            'Won Auctions', // enum id 7
            'Invoices', // enum id 8
            'Dashboard', // enum id 9
            'Settings', // enum id 10
            'Facility Mgmt', // enum id 11
            'Active Auctions', // enum id 12
            'Sold Auctions', // enum id 13
            'Unsold Auctions', // enum id 14
            'Canceled Auctions', // enum id 15
            'Canceled After Sold', // enum id 16
            'Draft Auctions', // enum id 17
            'Imported Auctions', // enum id 18
            'Live Auctions', // enum id 19
            'Invoices', // enum id 20
            'Reports', // enum id 21
            'Training', // enum id 22
            'Case Management', //enum id 23
            'My Offers', //enum id 24
            'Offer Acceptance Page', //enum id 25
            'Notifications', // enum id 26
            'Authorized Pickup Person Settings' // enum id 27
        ],

        global_alert: {
            title: 'Success',
            message: '',
            type: 'success',
            close_after: '',
            displaying: false
        },

        alert: {
            title: 'Success',
            message: '',
            type: 'success',
            close_after: '',
            displaying: false
        },

        containerClass: 'container',
        marginBottom: 'pageTitle marginB20'
    },
    windowWidth: 0,

    tablePageCount: 30,

    screen: {
        xxs: 320,
        xxs_max: 479,
        xs: 480,
        xs_max: 767,
        sm: 768,
        sm_max: 991,
        md: 992,
        md_max: 1199,
        lg: 1200
    }
}

export default (state = INITIAL_STATE, action) => {
    if (!state.data.loginLinkRedirect) state.data.loginLinkRedirect = loginLink(state.data.asPath)

    switch (action.type) {
        case SET_PAGE:
            state.data.title = action.title || ''
            state.data.h1 = action.h1 || ''
            state.data.description = action.description || ''
            state.data.marginBottom = action.marginBottom || 'pageTitle marginB20'

            state.title = (action.title ? action.title : 'Online Storage Unit Auctions') + ' | StorageTreasures.com'
            state.description = action.description

            return {...state, loading: false}
        case SET_PAGE_CONTAINER_CLASS:
            state.data.containerClass = get(action, 'containerClass', 'container')

            return {...state, loading: false}
        case SCREEN_RESIZE:
            return {...state, windowWidth: action.width, tablePageCount: action.width <= state.screen.md_max ? 10 : 30}
        case FACILITY_BOOKMARK_ADDED:

            typeof window != 'undefined' && window.toastr['success']("You have added this facility to your bookmarks.", "Success!")

            return {...state}
        case FACILITY_BOOKMARK_REMOVED:

            typeof window != 'undefined' && window.toastr['success']("You have removed this facility from your bookmarks.", "Success!")

            return {...state}
        case SET_GLOBAL_ALERT: // Custom alerts


            // Make sure there is a message to display and make sure the alert type is a valid type
            if (get(action, 'message', '') && typeof window != 'undefined' && window.toastr[get(action, 'alert_type', 'success')]) {

                let options = {}

                if (action.keepalive === true) {
                    options['preventDuplicates'] = true
                    options['timeOut'] = 0
                    options['extendedTimeOut'] = 0
                }

                typeof window != 'undefined' && window.toastr[get(action, 'alert_type', 'success')](get(action, 'message', ''), get(action, 'title'), options)
            }

            return {...state}
        case CLEAR_ALERT:

            state.data.global_alert = {
                title: 'Success',
                message: '',
                type: 'success',
                close_after: 10,
                displaying: false
            }
            state.data.alert = {
                title: 'Success',
                message: '',
                type: 'success',
                close_after: 10,
                displaying: false
            }

            typeof window != 'undefined' && window.toastr && window.toastr.remove()

            return {...state}
        case SET_DASHBOARD_PAGE:
            state.data.dashboard_title = action.dashboard_title || ''
            if (action.dashboard_menu_item) {
                state.data.dashboard_menu_item = action.dashboard_menu_item
            } else {
                state.data.dashboard_menu_item = state.data.dashboard_menu_items[action.dashboard_menu_enum] || ''
            }
            state.data.dashboard_menu_enum = action.dashboard_menu_enum || ''
            state.data.pageAction = action.pageAction
            return {...state, loading: false}

        case SEO_SET:
            return {...state, og: {...state.og, ...action.og}}
        case SEO_CLEAR:
            return {...state, og: {}}

        case PAGE_AS_ROUTE:
            return {...state, data: {...state.data, asPath: action.asPath, loginLinkRedirect: loginLink({redirect_to: action.asPath})}}

        default:
            return state
    }
}
