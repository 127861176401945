import React, { useMemo } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const Button = (props) => {
    const {
        label = false,
        className = '',
        type = 'button',
        variant = 'primary',
        disabled = false,
        loading = false,
        tooltip = null,
        children,
        ...otherProps
    } = props

    const classMap = {
        primary: 'btn btn-blue btn-regular w-100'
    }

    const buttonLabel = useMemo(() => {
        if (loading) {
            return <i className="fa fa-spin fa-spinner" />
        }
        return label
    }, [loading, label])

    const renderButton = useMemo(() => {
        const button = (
            <button
                {...otherProps}
                type={type}
                disabled={disabled}
                className={`${classMap[variant]} ${className}`}
            >
                {buttonLabel}
            </button>
        )

        if (tooltip) {
            return (
                <OverlayTrigger placement="top" overlay={<Tooltip id={`${type}-tooltip`}>{tooltip}</Tooltip>}>
                    {button}
                </OverlayTrigger>
            )
        }

        return button
    }, [otherProps, type, disabled, className, buttonLabel, variant, tooltip])

    return buttonLabel ? renderButton : children
}

export default Button
