export const GET_FM_ACTIVE_AUCTIONS = 'get_fm_active_auctions'
export const GET_FM_ACTIVE_AUCTIONS_FAIL = 'get_fm_active_auctions_fail'
export const GET_FM_ACTIVE_AUCTIONS_SUCCESS = 'get_fm_active_auctions_success'

export const ACTIVE_AUCTIONS_INPUT_CHANGED = 'active_auctions_input_changed'
export const ACTIVE_SEARCH_INPUT_CHANGED = 'active_search_input_changed'

export const GET_FM_SOLD_AUCTIONS = 'get_fm_sold_auctions'
export const GET_FM_SOLD_AUCTIONS_FAIL = 'get_fm_sold_auctions_fail'
export const GET_FM_SOLD_AUCTIONS_SUCCESS = 'get_fm_sold_auctions_success'

export const SOLD_AUCTIONS_INPUT_CHANGED = 'sold_auctions_input_changed'
export const SOLD_SEARCH_INPUT_CHANGED = 'sold_search_input_changed'

export const GET_FM_UNSOLD_AUCTIONS = 'get_fm_unsold_auctions'
export const GET_FM_UNSOLD_AUCTIONS_FAIL = 'get_fm_unsold_auctions_fail'
export const GET_FM_UNSOLD_AUCTIONS_SUCCESS = 'get_fm_unsold_auctions_success'

export const UNSOLD_AUCTIONS_INPUT_CHANGED = 'unsold_auctions_input_changed'
export const UNSOLD_SEARCH_INPUT_CHANGED = 'unsold_search_input_changed'

export const GET_FM_CANCELED_AUCTIONS = 'get_fm_canceled_auctions'
export const GET_FM_CANCELED_AUCTIONS_FAIL = 'get_fm_canceled_auctions_fail'
export const GET_FM_CANCELED_AUCTIONS_SUCCESS = 'get_fm_canceled_auctions_success'

export const CANCELED_AUCTIONS_INPUT_CHANGED = 'canceled_auctions_input_changed'
export const CANCELED_SEARCH_INPUT_CHANGED = 'canceled_search_input_changed'

export const FACILITY_MANAGER_TOP_AUCTIONS = 'facility_manager_top_auctions'
export const FACILITY_MANAGER_TOP_AUCTIONS_SUCCESS = 'facility_manager_top_auctions_success'
export const FACILITY_MANAGER_TOP_AUCTIONS_FAIL = 'facility_manager_top_auctions_fail'

export const FACILITY_MANAGER_METRICS = 'facility_manager_metrics'
export const FACILITY_MANAGER_METRICS_SUCCESS = 'facility_manager_metrics_success'
export const FACILITY_MANAGER_METRICS_FAIL = 'facility_manager_metrics_fail'

export const GET_FM_CANCELED_AFTER_SOLD_AUCTIONS = 'get_fm_canceled_after_sold_auctions'
export const GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_FAIL = 'get_fm_canceled_auctions_after_sold_fail'
export const GET_FM_CANCELED_AFTER_SOLD_AUCTIONS_SUCCESS = 'get_fm_canceled_auctions_after_sold_success'

export const CANCELED_AFTER_SOLD_AUCTIONS_INPUT_CHANGED = 'canceled_after_sold_auctions_input_changed'
export const CANCELED_AFTER_SOLD_SEARCH_INPUT_CHANGED = 'canceled_after_sold_search_input_changed'

export const GET_FM_DRAFT_AUCTIONS = 'get_fm_draft_auctions'
export const GET_FM_DRAFT_AUCTIONS_FAIL = 'get_fm_draft_auctions_fail'
export const GET_FM_DRAFT_AUCTIONS_SUCCESS = 'get_fm_draft_auctions_success'

export const DRAFT_AUCTIONS_INPUT_CHANGED = 'draft_auctions_input_changed'
export const DRAFT_SEARCH_INPUT_CHANGED = 'draft_search_input_changed'

export const DELETE_DRAFT_AUCTION = 'delete_draft_auction'
export const DELETE_DRAFT_AUCTION_SUCCESS = 'delete_draft_auction_success'
export const DELETE_DRAFT_AUCTION_FAIL = 'delete_draft_auction_fail'

export const GET_FM_IMPORTED_AUCTIONS = 'get_fm_imported_auctions'
export const GET_FM_IMPORTED_AUCTIONS_FAIL = 'get_fm_imported_auctions_fail'
export const GET_FM_IMPORTED_AUCTIONS_SUCCESS = 'get_fm_imported_auctions_success'

export const IMPORTED_SEARCH_INPUT_CHANGED = 'imported_search_input_changed'

export const FACILITY_MANAGER_GET_AUCTION_DETAILS = 'facility_manager_get_auction_details'
export const FACILITY_MANAGER_GET_AUCTION_DETAILS_SUCCESS = 'facility_manager_get_auction_details_success'
export const FACILITY_MANAGER_GET_AUCTION_DETAILS_FAIL = 'facility_manager_get_auction_details_fail'

export const GET_FM_FACILITIES = 'get_fm_facilities'
export const GET_FM_FACILITIES_FAIL = 'get_fm_facilities_fail'
export const GET_FM_FACILITIES_SUCCESS = 'get_fm_facilities_success'

export const FACILITIES_SEARCH_INPUT_CHANGED = 'facilities_search_input_changed'
export const CUSTOM_DATE_INPUT_CHANGED = 'custom_date_input_changed'

export const FACILITY_MANAGER_LIVE_AUCTION_FORM_UPDATE = 'facility_manager_live_auction_form_update'

export const FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS = 'facility_manager_get_live_auction_details'
export const FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_SUCCESS = 'facility_manager_get_live_auction_details_success'
export const FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_FAIL = 'facility_manager_get_live_auction_details_fail'

export const FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE = 'facility_manager_get_live_auction_save'
export const FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_SUCCESS = 'facility_manager_get_live_auction_save_success'
export const FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_FAIL = 'facility_manager_get_live_auction_save_fail'

export const GET_FM_LIVE_AUCTIONS = 'get_fm_live_auctions'
export const GET_FM_LIVE_AUCTIONS_FAIL = 'get_fm_live_auctions_fail'
export const GET_FM_LIVE_AUCTIONS_SUCCESS = 'get_fm_live_auctions_success'

export const LIVE_AUCTIONS_INPUT_CHANGED = 'live_auctions_input_changed'
export const LIVE_SEARCH_INPUT_CHANGED = 'live_search_input_changed'

export const CANCEL_LIVE_AUCTION = 'cancel_live_auction'
export const CANCEL_LIVE_AUCTION_FAIL = 'cancel_live_auction_fail'
export const CANCEL_LIVE_AUCTION_SUCCESS = 'cancel_live_auction_success'
