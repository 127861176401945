import { useGlobalContext } from 'context/GlobalContext';
import { AUCTION_BUYER } from 'helpers/users';
import { APP_CONFIG } from '../Config';

const PhoneLink = ({ children, prepend, append, isLink = true, user: propsUser, ...props }) => {
    const { user: _user } = useGlobalContext();
    const user = propsUser || _user;

    const show = user?.user_id && (!user?.reg_type == AUCTION_BUYER.id && user?.membership?.type) && user?.membership?.type !== 'Basic Bidder';

    const phoneNumber = user?.reg_type == AUCTION_BUYER.id ? APP_CONFIG?.REACT_APP_SUPPORT_PHONE_PRO : '(480) 397-6503';
    const phoneHref = user?.reg_type == AUCTION_BUYER.id
        ? '+' + APP_CONFIG?.REACT_APP_SUPPORT_PHONE_PRO
            .replace(/\s/g, '')
            .replace(/-/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')
        : '+4803976503';

    if (!user?.user_id) return null;
    if (user?.reg_type == AUCTION_BUYER.id && !user?.membership?.type) return null;
    if (user?.reg_type == AUCTION_BUYER.id && user?.membership?.type === 'Basic Bidder') return null;

    if (typeof children === 'function') return children({phoneNumber, phoneHref, show});

    const Component = isLink ? 'a' : 'span';

    return (
        <>
            {prepend}
            <Component
                href={`tel:${phoneHref}`}
                target="_blank"
                rel="nofollow noopener"
                {...props}
            >
                {phoneNumber}
            </Component>
            {append}
        </>
    );
};

export default PhoneLink;