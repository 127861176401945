import React from 'react';

interface LoadingItem {
    loading: boolean
    label: string
}

interface LoadingProps {
    show?: boolean
    loading_component?: LoadingItem[]
    className?: string
}

export const Loading = ({
    show = true,
    loading_component = [{ loading: true, label: 'Loading' }],
    className = '',
}: LoadingProps): React.JSX.Element | null => (!show ? null : (
    <div id="loading" className={`loading text-center ${className}`}>
        {loading_component.map(({ loading, label } = {} as LoadingItem, index) => (
            <h5 id={`loading-${index}`} key={`loading-${index}`}>
                <i className={`fa fa-${loading ? ' fa-spinner fa-spin' : 'check'}`} />
                {' '}
                {label}
            </h5>
        ))}
    </div>
));
