export interface UserType {
    id: number;
    name: string;
    label: string;
}

export enum UserTypeEnum {
    FacilityManager = 1,
    AuctionBuyer = 2,
    Auctioneer = 3,
    PrivateSeller = 4,
    BannedBuyer = -1,
    Unknown = -999
}

export const FACILITY_MANAGER: UserType = { id: UserTypeEnum.FacilityManager, name: 'facility-manager', label: 'Facility Manager' };
export const AUCTION_BUYER: UserType = { id: UserTypeEnum.AuctionBuyer, name: 'auction-buyer', label: 'Auction Buyer' };
export const AUCTIONEER: UserType = { id: UserTypeEnum.Auctioneer, name: 'auctioneer', label: 'Auctioneer' };
export const PRIVATE_SELLER: UserType = { id: UserTypeEnum.PrivateSeller, name: 'private-seller', label: 'Private Seller' };
export const BANNED_BUYER: UserType = { id: UserTypeEnum.BannedBuyer, name: 'banned-buyer', label: 'Banned Buyer' };

export const USER_TYPES: UserType[] = [FACILITY_MANAGER, AUCTION_BUYER, AUCTIONEER, PRIVATE_SELLER, BANNED_BUYER];

function userTypeToEnum(typeId: number | string | null | undefined): UserTypeEnum {
    const userType = (Object.values(UserTypeEnum).find(v => v == typeId) as unknown as UserTypeEnum) ?? UserTypeEnum.Unknown;
    return userType;
}

const getUserType = (predicate: (item: UserType) => boolean): UserType | undefined => {
    return USER_TYPES.find(predicate);
};

export const getUserTypeFromId = (typeId: number): UserType | undefined => {
    return (typeId > 0) ? getUserType(ut => ut.id === typeId) : undefined;
};

export const getUserTypeFromName = (typeName: string): UserType | undefined => {
    return getUserType(ut => ut.name === typeName);
};

export const getUserTypeName = (typeId: number): string | undefined => {
    return getUserTypeFromId(typeId)?.name;
};

export const getUserTypeId = (typeName: string): number | undefined => {
    if (typeName && (typeName ?? '').trim().length > 0) {
        return getUserType(ut => ut.name === typeName)?.id;
    }
    return undefined;
};

export const isFacilityManager = (typeId: number | null | undefined): boolean => {
    const userTypeEnum = userTypeToEnum(typeId);
    return userTypeEnum === UserTypeEnum.FacilityManager;
};

export const isAuctionBuyer = (typeId: number | null | undefined): boolean => {
    const userTypeEnum = userTypeToEnum(typeId);
    return userTypeEnum === UserTypeEnum.AuctionBuyer;
};

export const isAuctioneer = (typeId: number): boolean => {
    const userTypeEnum = userTypeToEnum(typeId);
    return userTypeEnum === UserTypeEnum.Auctioneer;
};

export const isPrivateSeller = (typeId: number | null | undefined): boolean => {
    const userTypeEnum = userTypeToEnum(typeId);
    return userTypeEnum === UserTypeEnum.PrivateSeller;
};

export const isBannedBuyer = (typeId: number): boolean => {
    const userTypeEnum = userTypeToEnum(typeId);
    return userTypeEnum === UserTypeEnum.BannedBuyer;
};


export const getUserPath = (type) => {
    const typeNumber = parseInt(type, 10);

    switch (typeNumber) {
        case FACILITY_MANAGER.id:
            return '/facility-manager';
        case AUCTION_BUYER.id:
            return '/auction-buyer';
        case PRIVATE_SELLER.id:
            return '/private-seller';
        case BANNED_BUYER.id:
            return '/banned';
        default:
            return '/facility-manager';
    }
};