import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import UserPageTitle from 'components/partials/UserPageTitle';
import NavLink from 'components/Page/NavLink';
import './styles.scss';

interface Item {
    id?: string;
    className?: string;
    name: string;
    sub?: { name: string; id?: string; link: string }[];
    link?: string;
}

interface UserSubNavigationProps {
    collapse_at_sm?: boolean;
    items: Item[];
    noTitle?: boolean;
}

const UserSubNavigation: React.FC<UserSubNavigationProps> = ({ items = [], noTitle }) => {
    const [open, setOpen] = useState(false);

    const menuEnum = useSelector((state: any) => state.page.data.dashboard_menu_enum);
    const menuItem = useSelector((state: any) => state.page.data.dashboard_menu_item);

    const toggleNavState = () => setOpen(!open);

    return (
        <>
            <Navbar className="user-sub-navigation-container" bg="dark" variant="dark" expand="lg" >
                <Container fluid="xl" className="p-0">
                    <Navbar.Toggle
                        aria-controls="navbar-nav"
                        onClick={toggleNavState}
                        className="navbar-container"
                        children={(
                            <Container fluid className='justify-content-between'>
                                {menuItem}
                                <div>
                                    {open ? <i className="fa fa-times"/> : <i className="fa fa-plus"/>}
                                </div>
                            </Container>
                        )}/>
                    <Navbar.Collapse id="navbar-nav">
                        <Nav className="justify-content-between">
                            {items
                                .filter((item) => !!item)
                                .map(({ className, name, sub = [], link }, index) => {
                                    const itemId = `account-nav-${index}`;

                                    return sub.length > 0 ? (
                                        <NavDropdown
                                            title={<>{name} <i className="fa fa-chevron-down sub-navigation-down" /></>}
                                            id={itemId}
                                            key={name}
                                            className={className}
                                        >
                                            {sub.map(({ name: subName, id: subId, link: subLink }, index) => (
                                                <NavDropdown.Item
                                                    key={`${subName}-${index}`}
                                                    as={NavLink}
                                                    to={subLink}
                                                >
                                                    {subName}
                                                </NavDropdown.Item>
                                            ))}
                                        </NavDropdown>
                                    ) : (
                                        <Nav.Link as={NavLink} to={link} key={name} id={itemId} className={className}>
                                            {name}
                                        </Nav.Link>
                                    );
                                })}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {noTitle || <UserPageTitle fm_dashboard={menuEnum === 'fm_dashboard'} />}
        </>
    );
};

export default UserSubNavigation;