import React, { Component } from 'react'
import { connect } from 'react-redux'
import Input from 'components/partials/Fields/FormikInput'
import Select from 'components/partials/Fields/FormikSelect'
import Countries from 'components/partials/Address/CountriesAndStates'
import CitiesSelect from 'components/Auctions/AuctionSearchPage/CitiesSelect'
import { setSelectedState } from 'actions'
import './styles.scss'

class TopSearchForm extends Component {
    input_updated = async (e, do_update = false) => {
        await this.props.search_obj_updated(e);

        if (do_update) {
            this.submitForm();
        }
    };

    /**
     * type needs to be updated in the search object first. if not then a race condition happens
     * and the term cleared out then re-set to the original value.
     * @param e
     * @returns {Promise<void>}
     */
    typeChange = async (e) => {
        const { name, value } = e.target;

        if (this.props.setSearchState) {
            return this.props.setSearchState({
                ...this.props.searchState,
                search_obj: {
                    ...this.props.searchState.search_obj,
                    term: '',
                    term_state: '',
                    term_city: '',
                    radius: this.props.defaultRadius,
                    type: value
                }
            })
        }

        await this.input_updated(e);
        await this.input_updated({ target: { name: 'term', value: '' } });
        await this.input_updated({ target: { name: 'term_state', value: '' } });
        await this.input_updated({ target: { name: 'term_city', value: '' } });

        if (name == 'type' && value != 'zipcode') {
            await this.input_updated({ target: { name: 'radius', value: this.props.defaultRadius } });
        }
    };

    renderSecondHalf = () => {
        const groupClose = (
            <div key="groupClose" className="input-group-btn">
                <button
                    disabled={this.props.disabled}
                    className="btn btn-dark main-search-submit"
                    id="main-search-submit"
                    type="submit"
                >
                    <i className="fa fa-search fa-2x" />
                </button>
            </div>
        );

        const {
            term = '',
            term_state = { label: '' },
            radius = '',
            type = 'zipcode',
            term_city
        } = this.props.search_obj;

        const {
            searchRadius = [],
            getStateName
        } = this.props;

        let radius_field = null;

        if (type !== 'state') {
            if (type === 'city') {
                return <>
                    <CitiesSelect
                        containerClassName="flex-1"
                        disabled={this.props.disabled}
                        name="term_city"
                        onChange={e => this.input_updated(e, true)}
                        value={term_city && {label: term_city, value: term_city}}
                        className={this.props.className}
                    />
                    {groupClose}
                </>
            }
            if (type === 'zipcode') {
                radius_field = (
                    <Select
                        frontEnd={false}
                        variant="info"
                        disabled={this.props.disabled}
                        name="radius"
                        value={radius}
                        onChange={this.input_updated}
                        instruction={false}
                        className={this.props.className}
                    >
                        {searchRadius.map((item, index) => (
                            <option key={index} value={item.id}>{item.name}</option>
                        ))}
                    </Select>
                );
            }

            return (
                <>
                    <Input
                        frontEnd={false}
                        disabled={this.props.disabled}
                        wrap={false}
                        key="term"
                        name="term"
                        value={term}
                        onChange={this.input_updated}
                        className={`form-control main-search width100 ${this.props.className}`}
                        attributes={{
                            placeholder: 'Enter search term(s)',
                        }}
                    />
                    {radius_field}
                    {groupClose}
                </>
            );
        }

        let {
            label = '',
        } = term_state;

        let do_lookup = false;

        if (!label) {
            do_lookup = true;
            label = term_state;
        }

        if (typeof label !== 'string') {
            label = '';
        }

        return (
            <>
                <Countries
                    containerClassName="flex-1"
                    disabled={this.props.disabled}
                    key="searchCountry"
                    name="term_state"
                    do_lookup={do_lookup}
                    getStateName={getStateName}
                    value={label}
                    updateMethod={(e) => this.input_updated(e, true)}
                    className={this.props.className}
                />
                {groupClose}
            </>
        );
    };

    submitForm = () => {
        const { search_obj, redoSearch } = this.props;
        search_obj.page_num = 1;
        redoSearch && redoSearch({ search_obj });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.submitForm();
    };

    render() {
        const {
            type = '',
        } = this.props.search_obj;

        return (
            <div className="top-search-container">
                <form onSubmit={this.handleSubmit}>
                    <div className="input-group">
                        <Select
                            containerClassName="w-md-auto w-xs-100"
                            variant="info"
                            frontEnd={false}
                            disabled={this.props.disabled}
                            name="type"
                            value={type}
                            onChange={this.typeChange}
                            instruction={false}
                            className={this.props.className}
                        >
                            {this.props.searchTypes.map((type, index) => (
                                <option key={index} value={type.id}>{type.name}</option>
                            ))}
                        </Select>
                        {this.renderSecondHalf()}
                    </div>
                </form>
            </div>
        );
    }
}

TopSearchForm.defaultProps = {
    search_obj: {},
    customSearchTypes: false,
    redoSearch: false,
    defaultRadius: 100,
    search_obj_updated() {},
};

const mapStateToProps = ({ auction, page }) => ({
    searchRadius: auction.searchRadius,
    searchTypes: auction.searchTypes,
    radius: auction.radius,
    term: auction.term,
    windowWidth: page.windowWidth,
});
export default connect(mapStateToProps, { setSelectedState })(TopSearchForm);
