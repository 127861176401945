const HelpBlock = ({ help }) => {
    if (!help) {
        return null;
    }

    return (
        <div className="help-block text-muted mt-2">{help}</div>
    );
};

export default HelpBlock;