import React, { ReactNode, ReactElement, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { RootState } from '../interfaces';
import NavLink from 'components/Page/NavLink';
import Link from 'next/link';
import Logout from 'components/UserAccount/Logout';
import HelpLink from 'components/HelpLink';
import Container from 'react-bootstrap/Container';
import MaintenanceNotice from 'layouts/MaintenanceNotice';
import { isPrivateSeller, isFacilityManager } from 'helpers/users';
import './styles.scss';

const LOGO = '/static/images/STLogoLarge.svg';
const LOGO_SMALL = '/static/images/STLogoSmall.svg';

interface LayoutHeaderProps {
    authed: boolean
    loginLinkRedirect: string
    children?: ReactNode[]
    noTitle?: boolean
    reg_type: string
    windowWidth: number
}

const LayoutHeader: React.FC<LayoutHeaderProps> = (props) => {
    const [selectedDropdown, setSelectedDropdown] = useState<string | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!(event.target as HTMLElement).closest('.nav-item')) {
                setSelectedDropdown(null);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleDropdownClick = (dropdownId: string) => {
        if (selectedDropdown === dropdownId) {
            setSelectedDropdown(null);
        } else {
            setSelectedDropdown(dropdownId);
        }
    };

    const {
        authed, loginLinkRedirect, children, noTitle, windowWidth, reg_type,
    } = props;

    const guest = !authed;

    const clonedChildren = children ? React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child as ReactElement<any>, { noTitle, key: child.key || `subnav-item-${index}` });
        }
        return child;
    }) : null;

    const isPs = isPrivateSeller(Number(reg_type));
    const isFm = isFacilityManager(Number(reg_type));

    return (
        <div className="navigation-wrapper">
            <MaintenanceNotice />
            <div className="main-nav-container">
                <div className="upper-nav-container">
                    <Container fluid className="upper-nav">
                        <Navbar.Brand as={Link} href="/" className="nav-bar-logo-container">
                            <a>
                                <img
                                    src={windowWidth <= 991 ? LOGO_SMALL : LOGO}
                                    alt="StorageTreasures Logo"
                                    className="nav-bar-logo cursor-pointer"
                                />
                            </a>
                        </Navbar.Brand>
                        <div className="buttons-container p-0">
                            {guest ? (
                                <>
                                    <NavLink to="/register" id="header-sign-up" className="btn btn-orange header-register">Sign Up</NavLink>
                                    <NavLink to={loginLinkRedirect} id="header-log-in" className="btn btn-blue header-login">Log In</NavLink>
                                </>
                            ) : (
                                <>
                                    <NavLink to="/account/dashboard" id="header-my-account" className="btn btn-orange header-register authed">My Account</NavLink>
                                    <Logout className="btn btn-blue header-login" />
                                </>
                            )}
                        </div>
                    </Container>
                </div>
                <div className="nav-container d-none d-lg-flex justify-content-center">
                    <Nav className="main-nav">
                        <Nav.Item className="main-nav-item-online-storage-auctions">
                            <Nav.Link as={Link} id="top-nav-online-storage-auctions" href="/auctions">
                                <a onClick={() => window.localStorage.removeItem('search_obj')}>
                                    Online Storage Auctions
                                </a>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="main-nav-item-live-storage-auctions">
                            <Nav.Link as={Link} id="top-nav-live-storage-auctions" href="/live-auctions">
                                <a onClick={() => window.localStorage.removeItem('live_auctions_search_obj')}>
                                    Live Storage Auctions
                                </a>
                            </Nav.Link>
                        </Nav.Item>
                        {(isFm || isPs) && (
                            <NavDropdown
                                title={<>Post Auction <i className="fa fa-chevron-down menu-dropdown-icon" /></>}
                                id="top-nav-post-auctions-dropdown"
                                onClick={() => handleDropdownClick('navbar-dropdown-post')}
                                style={{flex: 1.3}}
                            >
                                <NavDropdown.Item className="main-nav-dropdown-item" as={Link} id="top-nav-post-online-auction" href="/account/auctions/drafts/post">
                                    Online Auction
                                </NavDropdown.Item>
                                {!isPs && (
                                    <NavDropdown.Item className="main-nav-dropdown-item" as={Link} id="top-nav-post-live-auction" href="/account/live-auctions/post">
                                        Live Auction
                                    </NavDropdown.Item>
                                )}
                            </NavDropdown>
                        )}
                        <NavDropdown
                            title={<>Pro Community <i className="fa fa-chevron-down menu-dropdown-icon" /></>}
                            id="navbar-dropdown-pro-community"
                            onClick={() => handleDropdownClick('navbar-dropdown-pro')}
                            style={{flex: 1.6}}
                        >
                            <NavDropdown.Item className="main-nav-dropdown-item" href="http://blog.storagetreasures.com" target="_blank" rel="noopener noreferrer">
                                Auction Pro Blog
                            </NavDropdown.Item>
                            <NavDropdown.Item className="main-nav-dropdown-item" as={Link} id="top-nav-upgrade-to-pro" href="/pro-membership-upgrade">
                                Upgrade to Pro
                            </NavDropdown.Item>
                            <NavDropdown.Item className="main-nav-dropdown-item" as={Link} id="top-nav-auction-app" href="/online-storage-auction-app">
                                StorageTreasures App
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            title={<>About Us <i className="fa fa-chevron-down menu-dropdown-icon" /></>}
                            id="navbar-dropdown-about"
                            onClick={() => handleDropdownClick('navbar-dropdown-about')}
                            className={selectedDropdown === 'navbar-dropdown-about' ? 'nav-dropdown-selected' : ''}
                        >
                            <NavDropdown.Item className="main-nav-dropdown-item" as={Link} id="navbar-about" href="/about">
                                StorageTreasures
                            </NavDropdown.Item>
                            <NavDropdown.Item className="main-nav-dropdown-item" href="https://www.opentechalliance.com/about-us/" target="_blank" rel="noopener noreferrer" id="navbar-about-opentech">
                                OpenTech Alliance
                            </NavDropdown.Item>
                            <NavDropdown.Item className="main-nav-dropdown-item" href="https://www.charitystorage.org" target="_blank" rel="noopener noreferrer" id="navbar-about-charity-storage">
                                Charity Storage
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Item className="main-nav-dropdown-item">
                            <HelpLink showSupportEmail={false} id="navbar-help-center">
                                Help Center
                            </HelpLink>
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
            {clonedChildren}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const { user, page } = state;
    const {
        authed = false,
        reg_type = false,
    } = user;

    const {
        loginLinkRedirect,
    } = page.data || {};

    const {
        windowWidth,
    } = page;

    return {
        authed,
        reg_type: reg_type as string,
        loginLinkRedirect,
        windowWidth,
    };
};

export default connect(mapStateToProps)(LayoutHeader);