import UserSubNavigation from 'layouts/UserSubNavigation/UserSubNavigation';
import { useGlobalContext } from '../context/GlobalContext';

const BuyerNavigation = ({noTitle}) => {
    const { loading, user } = useGlobalContext();
    const showAuthorizedPickupPerson = !loading && user?.membership?.type && user?.membership?.type !== 'Basic Bidder';

    const navigation = [
        {
            name: 'Dashboard',
            id: 'dashboard',
            link: '/account/dashboard',
            className: 'dashboard-menu-item',
            enum: 1,
            sub: [],
        },
        {
            name: 'Settings',
            id: 'settings',
            link: '',
            className: 'dropdown dashboard-dropdown dashboard-menu-item',
            enum: [2, 3],
            sub: [
                {
                    name: 'Account Settings',
                    id: 'account-settings',
                    link: '/account/settings',
                    enum: 2,
                    sub: [],
                },
                ...(showAuthorizedPickupPerson ? [{
                    name: 'Authorized Pickup Person Settings',
                    id: 'authorized-pickup-person',
                    link: '/account/settings/authorized-pickup-person',
                    enum: 27,
                    sub: [],
                }] : []),
                {
                    name: 'Saved Searches',
                    id: 'saved-searches',
                    link: '/account/settings/saved-searches',
                    enum: 3,
                    sub: [],
                },
                {
                    name: 'Notification Settings',
                    id: 'notifications',
                    link: '/account/settings/notifications',
                    enum: 26,
                    sub: [],
                },
            ],
        },
        {
            name: 'Auction Tools',
            id: 'auction-tools',
            link: '',
            className: 'dropdown dashboard-dropdown dashboard-menu-item',
            enum: [4, 5, 6],
            sub: [
                {
                    name: 'AuctionWatch',
                    id: 'auctionwatch',
                    link: '/account/auction-tools/auction-watch',
                    enum: 4,
                    sub: [],
                },
                {
                    name: 'Bid Manager',
                    id: 'bid-manager',
                    link: '/account/auction-tools/bid-manager',
                    enum: 5,
                    sub: [],
                },
                {
                    name: 'Bookmarked Facilities',
                    id: 'bookmarked-facilities',
                    link: '/account/auction-tools/bookmarks',
                    enum: 6,
                    sub: [],
                },
            ],
        },
        {
            name: 'My Offers',
            id: 'my-offers',
            link: '/account/offers',
            className: 'dashboard-menu-item',
            enum: 24,
            sub: [],
        },
        {
            name: 'Won Auctions',
            id: 'won-auctions',
            link: '/account/auction-tools/won-auctions',
            className: 'dashboard-menu-item',
            enum: 7,
            sub: [],
        },
        {
            name: 'Invoices',
            id: 'invoices',
            link: '/account/invoices',
            className: 'dashboard-menu-item',
            enum: 8,
            sub: [],
        },
    ];

    return <UserSubNavigation items={navigation} collapse_at_sm fm_dashboard noTitle={noTitle} />;
};

export default BuyerNavigation;