import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import './styles.scss';

interface IconProps extends Omit<FontAwesomeIconProps, 'icon' | 'size'> {
    icon: IconDefinition;
    spin?: boolean;
    size?: SizeProp;
    className?: string;
    key?: string | null;
    onClick?: () => void;
}

export const Icon: React.FC<IconProps> = ({ 
    icon, 
    spin = false,
    size = 'lg',
    className = '', 
    key = null,
    onClick = () => {},
    ...props
}) => {
    return (
        <FontAwesomeIcon 
            icon={icon} 
            className={`font-awesome-icon ${className}`}
            spin={spin}
            size={size}
            key={key}
            onClick={onClick}
            {...props}
        />
    );
};