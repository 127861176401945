import React, { useState } from 'react';
import { connect } from 'react-redux';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavLink from 'components/Page/NavLink';
import Logout from 'components/UserAccount/Logout';
import SwitchClassic from 'components/SwitchClassic';
import HelpLink from 'components/HelpLink';
import { APP_CONFIG } from 'Config';
import { isPrivateSeller, isFacilityManager } from 'helpers/users';
import './styles.scss';

interface SidebarProps {
    authed: boolean
    loginLinkRedirect: string
    reg_type: string
    showTopMessageBar?: boolean
}

const Sidebar: React.FC<SidebarProps> = ({ authed, loginLinkRedirect, reg_type, showTopMessageBar }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [showOverlay, setShowOverlay] = useState<boolean | null>(null);
    const [selectedDropdown, setSelectedDropdown] = useState<string | null>(null);

    const toggleNav = () => {
        if (menuOpen) {
            closeNav();
        } else {
            openNav();
        }
    };

    const openNav = () => {
        setSelectedDropdown(null);
        setMenuOpen(true);
        setShowOverlay(true);
    };

    const closeNav = () => {
        setSelectedDropdown(null);
        setMenuOpen(false);
        setShowOverlay(false);
        setTimeout(() => setShowOverlay(null), 500);
    };

    const handleDropdownClick = (dropdownId: string) => {
        if (selectedDropdown === dropdownId) {
            setSelectedDropdown(null);
        } else {
            setSelectedDropdown(dropdownId);
        }
    };

    const renderLink = (link: string, title: string, { external = false } = {}, targetBlank = false) => {
        const rel = external ? 'noopener noreferrer' : undefined;

        return targetBlank ? (
            <a href={link} rel={rel} target="_blank" onClick={closeNav}>
                {title}
            </a>
        ) : (
            <NavLink to={link} rel={rel} onClick={closeNav}>
                {title}
            </NavLink>
        );
    };

    const getOverlayClassStatus = () => {
        if (showOverlay === true) return 'opened';
        if (showOverlay === false) return 'closed';
        return '';
    };

    const is_guest = !authed;
    const menuClassStatus = menuOpen ? 'opened' : 'closed';
    const maintenanceIconClass = APP_CONFIG?.REACT_APP_MAINTENANCE_NOTICE ? 'right-navbar-lower' : '';
    const isPs = isPrivateSeller(Number(reg_type));
    const isFm = isFacilityManager(Number(reg_type));

    return (
        <div className="sidebar-container sidebar-hide">
            <div className={`overlay ${getOverlayClassStatus()}`} onClick={closeNav} />

            <span
                className={`right-navbar-toggler ${menuClassStatus} ${maintenanceIconClass}`}
                aria-label="Toggle navigation"
                onClick={toggleNav}
            >
                <i className="toggle-menu fa fa-bars fa-2x" />
            </span>

            <div className={`navbar-side-left ${menuClassStatus}`} id="main-side-nav">
                <div className="slide-header">
                    <span className="slide-menu-title">Menu</span>
                    <span className={`right-navbar-toggler ${menuClassStatus} inside`} aria-label="Toggle navigation" onClick={toggleNav}>
                        <i className="toggle-menu fa fa-times fa-2x" />
                    </span>
                    <div className="slide-header-buttons">
                        <div className="col-xxs-6 col-xs-6">
                            {is_guest ? (
                                <NavLink className="btn btn-orange slide-header-button" id="sidebar-sign-up" to="/register" onClick={closeNav}>
                                    Sign Up
                                </NavLink>
                            ) : null}
                            {authed ? (
                                <NavLink className="btn btn-orange slide-header-button" id="sidebar-my-account" to="/account/dashboard" onClick={closeNav}>
                                    My Account
                                </NavLink>
                            ) : null}
                        </div>
                        <div className="col-xxs-6 col-xs-6">
                            {is_guest ? (
                                <NavLink className="btn btn-blue slide-header-button" id="sidebar-log-in" to={loginLinkRedirect} onClick={closeNav}>
                                    Log In
                                </NavLink>
                            ) : null}
                            {authed ? (
                                <Logout onClick={closeNav} className="btn btn-blue slide-header-button" />
                            ) : null}
                        </div>
                    </div>
                </div>
                {showTopMessageBar && <SwitchClassic sidebar />}
                <ul className="nav navbar-nav">
                    <li>{renderLink('/', 'Home')}</li>
                    <li>{renderLink('/auctions?type=zipcode&radius=100&filter_types=1&filter_types=2&filter_types=3', 'Online Auctions')}</li>
                    <li>{renderLink('/live-auctions?type=zipcode&radius=100term=', 'Live Auctions')}</li>
                    {(isFm || isPs) && (
                        <NavDropdown
                            title={<>Post Auction <i className="fa fa-chevron-down menu-dropdown-icon sidebar-down" /></>} 
                            id="sidebar__dropdown"
                            onClick={() => handleDropdownClick('navbar-dropdown-post')}
                            className={selectedDropdown === 'navbar-dropdown-post' ? 'nav-dropdown-selected-post' : ''}
                        >
                            <NavDropdown.Item as={NavLink} to="/account/auctions/drafts/post" onClick={closeNav}>
                                Online Auctions
                            </NavDropdown.Item>
                            {!isPs && (
                                <NavDropdown.Item as={NavLink} to="/account/live-auctions/post" onClick={closeNav}>
                                    Live Auction
                                </NavDropdown.Item>
                            )}
                        </NavDropdown>
                    )}
                    <NavDropdown
                        title={<>Pro Community <i className="fa fa-chevron-down menu-dropdown-icon sidebar-down" /></>}
                        id="pro-community-dropdown"
                        onClick={() => handleDropdownClick('navbar-dropdown-pro')}
                        className={selectedDropdown === 'navbar-dropdown-pro' ? 'nav-dropdown-selected-pro' : ''}
                    >
                        <NavDropdown.Item href="http://blog.storagetreasures.com" target="_blank" rel="noopener noreferrer" onClick={closeNav}>
                            Auction Pro Blog
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="/pro-membership-upgrade" onClick={closeNav}>
                            Upgrade to Pro
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="/online-storage-auction-app" onClick={closeNav}>
                            StorageTreasures App
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                        title={<>About Us <i className="fa fa-chevron-down menu-dropdown-icon sidebar-down" /></>}
                        id="about-us-dropdown"
                        onClick={() => handleDropdownClick('navbar-dropdown-about')}
                        className={selectedDropdown === 'navbar-dropdown-about' ? 'nav-dropdown-selected-about' : ''}
                    >
                        <NavDropdown.Item as={NavLink} to="/about" onClick={closeNav}>
                            StorageTreasures
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.opentechalliance.com/about-us/" target="_blank" rel="noopener noreferrer" onClick={closeNav}>
                            OpenTech Alliance
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.charitystorage.org" target="_blank" rel="noopener noreferrer" onClick={closeNav}>
                            Charity Storage
                        </NavDropdown.Item>
                    </NavDropdown>
                    <li>
                        <HelpLink>Help Center</HelpLink>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, page }: any) => {
    const { authed = false, reg_type = false } = user;
    const { loginLinkRedirect } = page.data || {};

    return {
        authed,
        reg_type,
        loginLinkRedirect,
    };
};

export default connect(mapStateToProps)(Sidebar);