import React from 'react'
import { APP_CONFIG } from 'Config'
import './styles.scss'

interface MaintenanceNoticeProps {}

const MaintenanceNotice: React.FC<MaintenanceNoticeProps> = () => {
    if (!APP_CONFIG?.REACT_APP_MAINTENANCE_NOTICE || APP_CONFIG.REACT_APP_MAINTENANCE_NOTICE === '') {
        return null
    }

    return (
        <div className="maintenance-notice d-flex justify-content-center align-items-center">
            <p>{APP_CONFIG.REACT_APP_MAINTENANCE_NOTICE}</p>
        </div>
    )
}

export default MaintenanceNotice