import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BSAlert from 'react-bootstrap/Alert'
import { clearAlerts } from 'actions'
import $ from 'jquery'

import 'styles/components/Alert.less'

class Alert extends Component {
    renderAlert = () => {
        const timer_id = 1233

        if (!this.props.alert || !this.props.alert.message) {
            return null
        }

        clearTimeout(timer_id)

        // If it's specified after some time hide the alert
        if (this.props.alert.close_after && this.props.alert.close_after > 0) {
            setTimeout(() => {
                // Fade out
                $('#regular-alert').removeClass('in')

                setTimeout(() => this.props.clearAlerts(), 66.67) // the bootstrap fade animation take 66.67 microseconds
            }, this.props.alert.close_after * 1000, timer_id)
        }

        return (
            <div id="regular-alert" className={`fade in alert alert-${ this.props.alert.type}`} role="alert">
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h2 className="alert-title">{this.props.alert.title}</h2>
                <div className="alert-message">
                    {this.props.alert.message}
                </div>
            </div>
        )
    }

    renderGlobalAlert = () => {
        const global_timer_id = 1232

        if (!this.props.global_alert || !this.props.global_alert.message) {
            return null // remove the message html
        }

        clearTimeout(global_timer_id)

        const show = ' in ' // fade in the message

        // If it's specified after some time hide the alert
        if (this.props.global_alert.close_after && this.props.global_alert.close_after > 0) {
            setTimeout(() => {
                // Fade out
                $('#global-alert').removeClass('in')

                // After fading is done clear out the alert contents
                setTimeout(() => this.props.clearAlerts(), 66.67) // the bootstrap fade animation take 66.67 microseconds
            }, this.props.global_alert.close_after * 1000, global_timer_id)
        }

        return (
            <div id="global-alert" className={`fade ${ show } alert alert-${ this.props.global_alert.type}`} role="alert">
                <div className={this.props.containerClass}>
                    <button type="button" className="close" onClick={this.closeGlobalAlert} aria-hidden="true">×</button>
                    <h2 className="alert-title">{this.props.global_alert.title}</h2>
                    <div className="alert-message">
                        {this.props.global_alert.message}
                    </div>
                </div>
            </div>
        )
    }

    closeGlobalAlert = () => {
        // Fade out
        $('#global-alert').removeClass('in')

        // After fading is done clear out the alert contents
        setTimeout(() => this.props.clearAlerts(), 66.67) // the bootstrap fade animation take 66.67 microseconds
    }

    render() {
        if (!this.props.type || this.props.type === 'global') {
            return this.renderGlobalAlert()
        }

        return this.renderAlert()
    }
}

const mapStateToProps = ({ page }) => ({
    global_alert: page.data.global_alert,
    alert: page.data.alert,
    containerClass: page.data.containerClass
})

export default connect(mapStateToProps, { clearAlerts })(Alert)

export const BasicAlert = ({
    message,
    title = 'Warning!',
    type = 'warning',
    show = true,
    onHide = false,
    ...props
}) => {
    const [shown, setShown] = useState(false)

    useEffect(() => {
        setShown(show)
    }, [show])

    if (!shown) {
        return null
    }

    const handleOnHide = () => {
        if (!onHide) {
            setShown(false)
        } else {
            onHide(false)
        }
    }

    return !show ? null : (
        <BSAlert
            variant={type}
            onClose={handleOnHide}
            {...props}
            dismissible
        >
            {title && <h4>{title}</h4>}
            <p className='pr-3 mb-0'>{message}</p>
        </BSAlert>
    )
}
