import SubmitCancelButtons from '../partials/SubmitCancelButtons'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'

const ConfirmationModal = ({
    show,
    onHide,
    loading,
    handleFormSubmit,
    handleCancelForm
}) => {
    return (
        <Modal
            size="md"
            onHide={onHide}
            show={show}
            hasClose
        >
            <ModalHeader closeButton>
                <ModalTitle>Update Membership</ModalTitle>
            </ModalHeader>
            <ModalBody className="manage-membership-modal_body">
                Are you sure you want to update your membership?
                <SubmitCancelButtons
                    show_loading={true}
                    loading={loading}
                    saving={loading}
                    submitLabel="Update"
                    cancelConfirmation={false}
                    handleCancelForm={handleCancelForm}
                    onClick={handleFormSubmit}
                />
            </ModalBody>
        </Modal>
    )
}

export default ConfirmationModal
