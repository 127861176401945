import React, { Component } from 'react'
import Link from 'components/Link'
import { connect } from 'react-redux'
import { get } from 'lodash'
import StartsCloses from '../Details/StartsCloses'
import AuctionImage from '../AuctionImage'
import AuctionRibbon from '../AuctionRibbon'
import { formatted_number } from 'helpers/number'
import { get_user_datetime} from 'helpers/date'
import { cityStateToUrl } from 'helpers/auctions'
import TimerProvider from 'context/TickContext'
import moment from 'moment'
import { withRouter } from 'next/router'
import { setNavigatingToAuction } from 'actions'

class AuctionTile extends Component {
    state = {
        isExpired: false,
        validExpiredTime: true,
        showContents: true
    }

    formatNumber = (number) => (parseInt(number) < 10 ? '0' : '') + number

    getTileImage = () => {
        // not small screen and a lg image is passed in then use the lg one
        const size = `image_path${ this.props.windowWidth <= 768 && get(this.props.auction.image, 'image_path') ? '_large' : ''}`
        // 3rd param could be a missing icon or something
        return get(this.props.auction.image, size)
    }

    renderAmountSection = () => {
        const {
            upcoming = false, current_bid, initial_bid, total_bids = 0
        } = this.props.auction

        switch (this.props.auction.status_id) {
            case '1': // active
                if (!upcoming) {
                    if (total_bids > 0) {
                        return (
                            <div className="item clock-container">
                                High Bid:
                                <div className="auction-tile-high-bid tile-amount">
                                    {formatted_number(current_bid)}
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div className="item clock-container">
                            Minimum Bid:
                            <div className="auction-tile-high-bid tile-amount">
                                {formatted_number(initial_bid)}
                            </div>
                        </div>
                    )
                }

                return (
                    <div className="item clock-container">
                        Minimum Bid:
                        <div className="auction-tile-high-bid tile-amount">
                            {formatted_number(initial_bid)}
                        </div>
                    </div>
                )
            case '2': // sold
            case '3': // unsold
            case '4': // canceled
                return (
                    <div className="item clock-container">
                        Final High Bid:
                        <div className="auction-tile-high-bid tile-amount">
                            {formatted_number(current_bid)}
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    handleExpiredTime = () => {
        this.setState({ isExpired: true, validExpiredTime: true }, () => {
            this.props.onExpired && this.props.onExpired(true, true)
        })
    }

    renderTimer = () => {
        const {
            current_date: currentDate
        } = this.props.auction || {}

        return (
            <TimerProvider currentDate={parseInt(moment(get_user_datetime(currentDate)) + '')}>
                <StartsCloses
                    auction={this.props.auction}
                    handleExpiredTime={this.handleExpiredTime}
                    auctionTile
                />
            </TimerProvider>
        )
    }

    chompContents = (vertical) => {
        const LGCharsPerLine = 50
        const MDCharsPerLine = 45
        const max_lines = 2

        // horizontal char limit
        let charLimit = this.props.windowWidth >= 1200 ? LGCharsPerLine * max_lines : MDCharsPerLine * max_lines

        if (vertical) {
            charLimit = this.props.windowWidth >= 992 ? 166 : 80
        }

        if (this.props.auction && this.props.auction.unit_contents) {
            const doubleTest = this.props.auction.unit_contents
            const add_ellipsis = doubleTest.length > charLimit ? '...' : ''
            return doubleTest.slice(0, charLimit) + add_ellipsis
        }

        return ''
    }

    renderAppearsToContain = (vertical) => {
        const showContents = get(this.props, 'showContents', true)
        if (showContents == true && showContents !== undefined) {
            return (
                <div className="unit-contains">
                    <h3 className="contains-label">
                        Unit Appears to Contain:
                    </h3>
                    <div className="text">
                        {this.chompContents(vertical)}
                    </div>
                </div>
            )
        }

        return null
    }

    navigateToAuction = (path) => {
        this.props.setNavigatingToAuction(true)
        window.scrollTo(0, 0)
        this.props.router.push(path)
    }

    renderHorizontalTile = () => {
        let auctionTileClass = 'auction-tile horizontal-tile'

        if (this.state.validExpiredTime && this.state.isExpired) {
            auctionTileClass += ' expired'
        }

        if (!this.state.validExpiredTime) {
            auctionTileClass += ' invalid-time'
        }

        const {
            auction_id, unit_width, city, state, facility_name, unit_length
        } = this.props.auction

        const cityStateUrl = cityStateToUrl({state, city})

        const linkContent = (
            <>
                <div className="section tile-image">
                    <AuctionImage
                        auction={this.props.auction}
                        image_url={this.getTileImage()}
                    />
                </div>
                <div className="section tile-countdown">
                    <div className="tile-bid-amount">
                        {this.renderAmountSection(false)}
                    </div>
                    <div className="tile-counter">
                        {this.renderTimer()}
                    </div>
                </div>
                <div className="section tile-details">
                    <h3 className="facility-name">{facility_name}</h3>
                    <div className="city-state">
                        {`${city}, ${state}`}
                        <br />
                    </div>
                    <div className="unit-size">
                        Approx. Size:
                        {' '}
                        {`${unit_width} x ${unit_length}`}
                    </div>
                    {this.renderAppearsToContain(false)}
                </div>
            </>
        )

        if (this.props.otherAuctions) {
            return (
                <div
                    onClick={() => this.navigateToAuction(`/auctions/${cityStateUrl}/${auction_id}`)}
                    id={`auction-tile-${auction_id}`}
                    className={`${auctionTileClass} cursor-pointer`}
                >
                    {linkContent}
                </div>
            )
        } else {
            return (
                <Link to={`/auctions/${cityStateUrl}/${auction_id}`} id={`auction-tile-${auction_id}`} className={auctionTileClass}>
                    {linkContent}
                </Link>
            )
        }
    }

    renderVerticalTile = () => {
        const { auction } = this.props

        let auctionTileClass = 'auction-tile vertical-tile'

        if (this.state.validExpiredTime && this.state.isExpired) {
            auctionTileClass += ' expired'
        }

        if (!this.state.validExpiredTime) {
            auctionTileClass += ' invalid-time'
        }

        const {
            auction_id, unit_width, city, state, facility_name, unit_length
        } = auction

        const cityStateUrl = cityStateToUrl({state, city})

        const linkContent = (
            <>
                <div className="section tile-image">
                    <AuctionImage
                        auction={auction}
                        image_url={this.getTileImage()}
                        needs_bottom={false}
                    />
                </div>
                <div className="section tile-auction-status">
                    <AuctionRibbon position="bottom" auction={auction} />
                </div>
                <div className="section tile-countdown">
                    <div className="tile-bid-amount">
                        {this.renderAmountSection(true)}
                    </div>
                    <div className="tile-counter">
                        {/* auctions only show countdown if the auction is active */}
                        {this.props.auction.status_id == 1 && this.renderTimer()}
                    </div>
                </div>
                <div className="section tile-details">
                    <h3 className="facility-name">{facility_name}</h3>
                    <div className="city-state">
                        {`${city}, ${state}`}
                        <br />
                    </div>
                    <div className="unit-size">
                        Approx. Size:
                        {' '}
                        {`${unit_width} x ${unit_length}`}
                    </div>
                    {this.renderAppearsToContain(true)}
                </div>
            </>
        )

        if (this.props.otherAuctions) {
            return (
                <div
                    onClick={() => this.navigateToAuction(`/auctions/${cityStateUrl}/${auction_id}`)}
                    id={`auction-tile-${auction_id}`}
                    className={`${auctionTileClass} cursor-pointer`}
                >
                    {linkContent}
                </div>
            )
        } else {
            return (
                <Link to={`/auctions/${cityStateUrl}/${auction_id}`} id={`auction-tile-${auction_id}`} className={auctionTileClass}>
                    {linkContent}
                </Link>
            )
        }
    }

    render() {
        if (this.props.allowHorizontal && this.props.windowWidth >= 992) {
            return this.renderHorizontalTile()
        }

        return this.renderVerticalTile()
    }
}

AuctionTile.defaultProps = {
    allowHorizontal: false,
    onExpired(validTimer = true, isExpired = false) {
        // This is a callback that fires as soon as the time is expired
    },
    auction: {},
    otherAuctions: false
}

const mapStateToProps = ({ page }) => ({
    windowWidth: page.windowWidth
})

const mapActionToProps = {
    setNavigatingToAuction
}

export default withRouter(connect(mapStateToProps, mapActionToProps)(AuctionTile))
