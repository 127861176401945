import React, { ReactNode } from 'react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'components/Icon';

interface DropdownToggle {
    children?: ReactNode;
    className?: string;
    labelClassName?: string;
    [key: string]: any;
}

const DropdownToggle = React.forwardRef<HTMLButtonElement, DropdownToggle>(({
    children,
    className,
    labelClassName,
    ...props
}, ref) => {
    const customClassName = className?.replace('dropdown-toggle', '');

    return (
        <button
            ref={ref}
            className={`btn btn-muted px-3 d-flex align-items-center gap-xs-1 ${customClassName}`}
            {...props}
        >
            <span className={` ${labelClassName}`}>{children}</span>
            <Icon size="lg" icon={faAngleDown} />
        </button>
    );
});

export default DropdownToggle;