import React from 'react';
import BaseSelect from './BaseSelect';
import { Field } from 'formik';

const Select = ({
    formik,
    nested = false,
    ...props
}) => {
    if (formik) {
        return <Field
            name={props.name}
        >
            {({field, form}) => {

                const fieldGroup = props?.name?.split('.')[0];
                const fieldName = props?.name?.split('.')[1];
                const touched = !nested ? form?.touched[props.name] : form?.touched?.[fieldGroup]?.[fieldName];
                const errorString = !nested ? form?.errors[props.name] : form?.errors?.[fieldGroup]?.[fieldName];

                return (
                    <BaseSelect error={touched && errorString} {...props} {...field} />
                );
            }}
        </Field>;
    }

    return (
        <BaseSelect {...props} />
    );
};

export default Select;