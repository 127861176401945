import React, { Component } from 'react'
import { connect } from 'react-redux'
import { includes } from 'lodash'
import { doLogout } from 'actions'
import { logout } from 'actions/AuthActions'

interface LogoutProps {
    text?: string
    className?: string
    withRedirect?: string
    withReload?: boolean
    onClick?: () => void
    history?: any
    logout: () => void
    doLogout: () => void
}

interface LogoutState {
    action: boolean
    redirect_to: string
}

class Logout extends Component<LogoutProps, LogoutState> {
    state = {
        action: false,
        redirect_to: '/'
    }

    doLogout = () => {
        const { withRedirect, withReload, onClick } = this.props

        this.props.logout()
        this.props.doLogout()
        // Clear local storage  
        localStorage.removeItem('search_obj')
        localStorage.removeItem('live_auctions_search_obj')
        
        // Logout requires a page reload to propagate everything throughout
        if (withRedirect) {
            this.props.history.replace(withRedirect)
        } else if (withReload === true) {
            window.location.reload()
        }

        if (includes(window.location.pathname, '/account')) {
            window.location.replace('/')
        }

        if (onClick) onClick()
    }

    render() {
        const { text = 'Log Out', className = 'btn btn-blue' } = this.props

        return (
            <a href="#" onClick={this.doLogout} className={className} title={text} id="logout">
                {text}
            </a>
        )
    }
}

const mapStateToProps = () => ({})

const mapActionsToProps = {
    logout,
    doLogout
}

export default connect(mapStateToProps, mapActionsToProps)(Logout)