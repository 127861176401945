import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import SubmitCancelButtons from 'components/partials/SubmitCancelButtons';
import Select from 'components/partials/Fields/FormikSelect';
import Input from 'components/partials/Fields/FormikInput';
import { Form } from 'components/FormikForm';
import yup from 'helpers/validation';
import useService from 'hooks/useService';
import { PUT } from 'services/RequestMethods';
import { general_error_toastr, general_success_toastr } from '../partials/Notification';
import ConfirmationModal from './ConfirmationModal';
import { useDispatch } from 'react-redux';
import { CHANGE_MEMBERSHIP_SUCCESS } from '../../actions/UserTypes';
import moment from 'moment';
import { useGlobalContext } from 'context/GlobalContext';
import { APP_CONFIG } from 'Config';
import './styles.less';

const plans = [
    {label: 'Basic Bidder', value: 'Basic Bidder'},
    {label: 'ProBidder ($10/month)', value: 'ProBidder'},
    {label: 'ProBidder Max ($20/month)', value: 'ProBidder Max'},
    {label: 'ProBusiness ($30/month)', value: 'ProBusiness'},
];

const upgradedOrDowngraded = ({oldMembership, newMembership}) => {
    if (plans.find(plan => plan.value === newMembership).value > plans.find(plan => plan.value === oldMembership).value) {
        return 'upgraded';
    }

    return 'downgraded';
};

const ManageMembershipModal = ({
    membership_type,
    user_id,
    payment_method,
    handleMembershipSubmitSuccess,
    showPromoCodeInput = false,
    onHide,
    show,
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [_, fireChangeUserMembership] = useService({
        url: 'users/account/membership',
        method: PUT,
    });
    const [showConfirm, setShowConfirm] = useState(false);
    const [typeValue, setTypeValue] = useState('');
    const { getUser, user } = useGlobalContext();
    const { REACT_APP_UPDATE_MEMBERSHIP } = APP_CONFIG;

    const initialValues = {
        type: '',
    };

    const formSchema = yup.object().shape({
        promo_code: yup
            .string()
            .matches(/^[a-zA-Z0-9\s]+$/, 'Promo code can only contain alphanumeric characters and spaces.'),
        type: yup
            .string()
            .required(' '),
    });

    const handleFormSubmit = async () => {
        setLoading(true);

        const previousMembership = membership_type;

        const updateOrDowngrade = upgradedOrDowngraded({
            oldMembership: previousMembership,
            newMembership: typeValue,
        });

        if (user?.membership?.interval_length === 'annually' &&
            user?.membership?.previous === 'Pro' &&
            updateOrDowngrade === 'upgraded') {
            onHide();
            setShowConfirm(false);
            setLoading(false);
            return general_error_toastr({
                title: false,
                message: 'Please contact Customer Support for assistance.',
            });
        }

        try {
            const { status, message, renewal_date: renewalDate  } = await fireChangeUserMembership({
                url: 'users/account/membership',
                params: {type: typeValue},
            });

            if (status === 'failed') {
                throw message;
            }

            if (status === 'updated') {
                await getUser();

                onHide();
                handleMembershipSubmitSuccess();
                dispatch({type: CHANGE_MEMBERSHIP_SUCCESS, payload: {membership_type: typeValue}});

                const formattedRenewalDate = moment(renewalDate).format('MM/DD/YYYY');

                return general_success_toastr({
                    message: upgradedOrDowngraded({
                        oldMembership: previousMembership,
                        newMembership: typeValue,
                    }) === 'upgraded'
                        ? 'Membership update successful.'
                        : `Request received. New membership starts on ${formattedRenewalDate}`,
                });
            } else {
                throw 'An error occurred. Please try again!';
            }
        } catch (error) {
            general_error_toastr({
                title: false,
                message: error,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!show) {
            setShowConfirm(false);
            setTypeValue('');
        }
    }, [show]);

    return (
        <>
            <Modal
                size="md"
                onHide={onHide}
                show={show && !showConfirm}
                hasClose
            >
                <ModalHeader closeButton>
                    <ModalTitle>Manage Membership</ModalTitle>
                </ModalHeader>
                <ModalBody className="manage-membership-modal_body">
                    {!payment_method ? (
                        <div className="alert alert-danger cc-error-text">
                            <strong>Oops, that didn't work!</strong>
                            {' '}
                            You need a valid payment method on file before you're eligible to upgrade. Please update your payment
                            method and try again.
                        </div>
                    ) :
                        <Form
                            onSubmit={({type}) => {
                                setTypeValue(type);
                                setShowConfirm(true);
                            }}
                            initialValues={initialValues}
                            validationSchema={formSchema}
                        >
                            <Select
                                formik
                                label="Select a Membership:"
                                name="type"
                                addBlank
                            >
                                {plans
                                    .filter(plan => plan.value !== membership_type)
                                    .map((plan, index) =>
                                        <option key={index} value={plan.value}>{plan.label}</option>)
                                }
                            </Select>
                            <p className="text-center">
                                <a href="/pro-membership-upgrade" target="_blank">
                                    Learn more about the benefits of an upgraded membership.
                                </a>
                            </p>
                            {showPromoCodeInput && (
                                <>
                                    <br/>
                                    <br/>
                                    <Input
                                        formik
                                        type="text"
                                        label="Have a Promo Code?"
                                        name="promo_code"
                                    />
                                </>
                            )}
                            <SubmitCancelButtons
                                show_loading={true}
                                loading={loading}
                                saving={loading}
                                submitLabel="Update"
                                cancelConfirmation={false}
                                handleCancelForm={onHide}
                                submit_disable={!REACT_APP_UPDATE_MEMBERSHIP}
                            />
                        </Form>
                    }
                </ModalBody>
            </Modal>
            <ConfirmationModal
                onHide={() => setShowConfirm(false)}
                show={show && showConfirm}
                loading={loading}
                handleFormSubmit={handleFormSubmit}
                handleCancelForm={() => setShowConfirm(false)}
            />
        </>
    );
};

export default ManageMembershipModal;
