import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import AuctionRibbon from './AuctionRibbon'

class AuctionImage extends Component {
    render() {
        const {
            auction,
            image_url,
            needs_bottom,
            className,
            has_ribbon
        } = this.props

        return (
            <div className={`tile-image ${className}`}>
                <div className="auction-image">
                    {has_ribbon ? <AuctionRibbon position="top" auction={auction}/> : null}
                    <div style={{backgroundImage: `url(${image_url})`}} className="auction-img"/>
                    {needs_bottom ? <AuctionRibbon position="bottom" auction={auction}/> : ''}
                </div>
                <div className="clearfix"/>
            </div>
        )
    }
}

AuctionImage.defaultProps = {
    auction: {},
    image_url: '',
    className: '',
    needs_bottom: true,
    has_ribbon: true
}

AuctionRibbon.propTypes = {
    auction: PropTypes.object,
    image_url: PropTypes.string,
    className: PropTypes.string,
    needs_bottom: PropTypes.bool
}

const mapStateToProps = ({ user }) => {
    const { authed } = user
    return {
        authed
    }
}

export default connect(mapStateToProps)(AuctionImage)
