import axios from 'axios'
import { environment } from '../services/environment'

const axiosInstance = axios.create({
    baseURL: environment.apiEndPoint
})

const pendingRequests = new Map()

axiosInstance.interceptors.request.use(config => {
    const requestIdentifier = `${config.url}_${config.method}`

    if (pendingRequests.has(requestIdentifier)) {
        const cancelTokenSource = pendingRequests.get(requestIdentifier)

        cancelTokenSource.cancel('Cancelled due to new request')
    }

    const newCancelTokenSource = axios.CancelToken.source()
    config.cancelToken = newCancelTokenSource.token

    pendingRequests.set(requestIdentifier, newCancelTokenSource)
    return config
}, error => {
    return Promise.reject(error)
})

axiosInstance.interceptors.response.use(response => {
    const requestIdentifier = `${response.config.url}_${response.config.method}`
    pendingRequests.delete(requestIdentifier)
    return response
}, error => {
    if (error.config) {
        const requestIdentifier = `${error.config.url}_${error.config.method}`
        pendingRequests.delete(requestIdentifier)
    }
    return Promise.reject(error)
})

export default axiosInstance
