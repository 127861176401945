import React, { useState, useEffect } from 'react';
import { Loading } from 'components/Loading';
import Carousel from '../partials/Carousel/Carousel';
import { debounce } from 'lodash';
import MustKnowModal from 'components/MustKnowModal';
import { Notification } from 'types/Notification';

interface MustKnowProps {
    notification_loading: boolean
    notifications?: Notification[]
    images: string[]
}

const MustKnow: React.FC<MustKnowProps> = ({ notification_loading, notifications = [], images }) => {
    const [activeSlide, setActiveSlide] = useState<number | null>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        const getImage = () => {
            const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

            if (innerWidth < 320) {
                setImage(images[0]); // xxs
            } else if (innerWidth < 480) {
                setImage(images[1]); // xs
            } else if (innerWidth < 768) {
                setImage(images[2]); // sm
            } else if (innerWidth < 992) {
                setImage(images[3]); // md
            } else {
                setImage(images[4]); // lg
            }
        };

        getImage();
        window.addEventListener('resize', getImage);

        return () => {
            window.removeEventListener('resize', getImage);
        };
    }, [images]);

    const toggleModal = (slideIndex: number | null) => {
        if (slideIndex !== null) {
            setActiveSlide(slideIndex);
            return setShowModal(true);
        }

        setShowModal(false);
        debounce(() => setActiveSlide(null), 500);
    };

    if (notification_loading) {
        return (
            <Loading loading_component={[{ label: 'Loading', loading: notification_loading }]} />
        );
    }

    if (notifications.length === 0) {
        return null;
    }

    return (
        <div className="must-know home-hero">
            <div className="must-know-background must-know-bg-image" />
            <div className="container">
                <div className="must-know-image-container must-know-bg-image">
                    <img src={image} alt="Must Know" className="must-know-image" />
                </div>
            </div>
            <div className="text-center">
                <div className="carousel-content">
                    <Carousel name="homepage-must-know-carousel" height="90%" accentColor="white" timeout="5000">
                        {notifications.map(({ subject, excerpt }, index) => (
                            <div key={index} className="item-body">
                                <div className="must-know-title">
                                    {subject}
                                </div>
                                <div className="must-know-body">
                                    {excerpt}
                                </div>
                                <div className="must-know-action">
                                    <button
                                        data-toggle="modal"
                                        data-target="#learn-more"
                                        className="btn btn-orange btn-block btn-regular"
                                        onClick={() => toggleModal(index)}
                                    >
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
            <MustKnowModal
                show={showModal}
                onHide={() => toggleModal(null)}
                notifications={notifications}
                loading={notification_loading}
                activeSlide={activeSlide}
            />
        </div>
    );
};

export default MustKnow;