import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import { environment } from 'services/environment';
import noDupAxios from 'helpers/noDupAxios';
import moment from 'moment';
import { isAuctionBuyer } from 'helpers/users';

const getBearerToken = async () => {
    const { idToken: { jwtToken } = {} } = (await Auth.currentSession()) || {};

    return jwtToken;
};

let lastCheckedEmail = '';
let lastCheckedEmailResult = true;
export const checkUniqueEmail = async (email, initEmail) => {
    if (email === initEmail) {
        return true;
    }

    if (email === lastCheckedEmail) {
        return lastCheckedEmailResult;
    }

    if (!email || !email.includes('@')) {
        return true;
    }

    lastCheckedEmail = email;

    try {
        const {data: {message}} = await noDupAxios({
            url: environment.apiEndPoint + 'users/check/email-exists',
            headers: {
                'x-api-key': environment.apiKey,
                'content-type': 'application/json',
            },
            params: {
                email: email,
            },
        });


        lastCheckedEmailResult = message !== 'true';
        return lastCheckedEmailResult;
    } catch (error) {
        lastCheckedEmailResult = true;
        return true;
    }
};

let _lastCheckedPhone = '';
let _lastCheckedPhoneResult = true;
export const checkUniquePhone = async ({
    ctx,
    value,
    initial,
    msg,
    regType,
    requirePhone,
}) => {
    const { path, createError } = ctx;

    if (value === undefined) {
        return true;
    }

    const _value = value.replace(/\D/g, '');

    if (_value === initial) {
        return true;
    }

    const prefix = ctx.parent.phone_prefix || '1';
    const phoneNumber = _value.slice(prefix.length);

    if (phoneNumber.length < 10) {
        return true;
    }

    if (_value === _lastCheckedPhone) {
        return _lastCheckedPhoneResult;
    }

    if (phoneNumber.length === 10) {
        _lastCheckedPhone = _value;
    }

    try {
        const { data } = await noDupAxios({
            url: environment.apiEndPoint + 'users/check/phone-exists',
            method: 'GET',
            headers: {
                'x-api-key': environment.apiKey,
                'content-type': 'application/json',
            },
            params: {
                phone_number: phoneNumber,
                phone_prefix: prefix,
                reg_type: regType,
                require_phone: requirePhone,
            },
        });

        if (data.exists) {
            _lastCheckedPhoneResult = createError({ path, message: msg });
            return _lastCheckedPhoneResult;
        }

        _lastCheckedPhoneResult = true;
        return true;
    } catch (error) {
        _lastCheckedPhoneResult = true;
        return true;
    }
};

yup.addMethod(yup.string, 'dupePhone', function (
    msg = dupePhoneErrorMap[requirePhone ? 'mfa' : 'dup'],
    requirePhone = false,
    regType = '',
    initial = '',
) {
    return this.test('phone-check', msg, function (value) {
        if (!isAuctionBuyer(regType)) {
            return true;
        }
        
        return checkUniquePhone({
            ctx: this,
            value,
            initial,
            msg,
            regType,
            requirePhone,
        });
    });
});

yup.addMethod(yup.string, 'alpha', function (msg) {
    return this.matches(/^[A-Za-z\s]+$/, msg);
});

yup.addMethod(yup.number, 'percent', function (msg) {
    return this
        .typeError(msg)
        .integer('Only whole numbers allowed')
        .min(0, 'This value should be between 0 and 100.')
        .max(100, 'This value should be between 0 and 100.');
});

yup.addMethod(yup.number, 'wholeNumber', function (msg) {
    return this
        .typeError(msg)
        .integer('Only whole numbers allowed')
        .min(0, 'This value should be a positive number');
});

yup.addMethod(yup.string, 'dupeEmail', function (
    msg = 'Email has already been registered.',
    initEmail = '',
) {
    return this.test('email-check', msg, val => checkUniqueEmail(val, initEmail));
});

const dupePhoneErrorMap = {
    mfa: 'Phone has already been verified for another account.',
    dup: 'Phone has already been registered.',
};


let _originalValueValid = '';
let changedValid = false;
yup.addMethod(yup.string, 'validPhone', function (msg = 'Please enter a valid phone.', isRequired = true) {
    return this
        .test(
            'validPhone',
            msg,
            (phone, { originalValue }) => {
                if (!changedValid) {
                    _originalValueValid = originalValue;
                    changedValid = true;
                }

                // For non-required fields, if the only value is the prefix ("+1" or "1"), treat as empty
                if (!isRequired && (!phone || phone === '+1' || phone === '1' || phone.trim() === '')) {
                    return true;
                }

                if (phone !== undefined) {
                    let _value = phone.replace(/\D/g, '');
                    return _value.length > 10;
                }
                
                // For required fields, we still want to return false
                return !isRequired;
            });
});

yup.addMethod(yup.string, 'strongPassword', function (msg) {
    return this
        .typeError(msg)
        .default(false)
        .nullable(true)
        .trim()
        .ensure()
        .when('password_confirm', (password_confirm, schema) => {
            if (password_confirm || password_confirm === undefined) {
                return schema
                    .matches(
                        /^.*(?=.{6,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                        'Please make sure to fill out all of the Password Requirements marked in red.',
                    )
                    .required(
                        'Please make sure to fill out all of the Password Requirements marked in red.',
                    );
            } else {
                return schema.nullable().notRequired();
            }
        });
});

yup.addMethod(yup.string, 'prevPassword', function (
    msg = 'Please make sure to fill out all of the Password Requirements marked in red.',
    userId,
    fireCheck = false,
) {
    return this
        .test(
            'password-check',
            msg,
            async _password => {
                if (fireCheck && _password.length > 0) {
                    const jwtToken = await getBearerToken();

                    const { data: { password }} = await noDupAxios({
                        method: 'POST',
                        url: environment.apiEndPoint + 'users/account/check/password',
                        headers: {
                            'x-api-key': environment.apiKey,
                            'content-type': 'application/json',
                            'Authorization': `Bearer ${jwtToken}`,
                        },
                        data: { check: _password },
                    });
                    return password.last_five !== true || password.six_months !== true;
                }

                return true;
            },
        );
});

yup.addMethod(yup.string, 'passwordConfirmation', function (msg) {
    return this
        .typeError(msg)
        .default(false)
        .nullable(true)
        .trim()
        .ensure()
        .test('passwordConfirmation', 'Passwords do not match', (value) => {
            try {
                if (value === password.value) {
                    return true;
                }
                return false;
            } catch (e) {
                return false;
            }
        });
});

yup.addMethod(yup.string, 'validDate', function (msg = 'Invalid date format') {
    return this
        .test(
            'validDate',
            msg,
            date => moment(date).format('MM/DD/YYYY') === date,
        );
});

yup.addMethod(yup.string, 'dateInFuture', function (msg = 'Start date cannot be in the past') {
    return this.test(
        'dateInFuture',
        msg,
        date => moment(date).isAfter(moment().subtract(1, 'day')),
    );
});

export default yup;