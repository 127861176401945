/**
    Figure out how to read the .env file. For now lets use hard coded variables
 **/

import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig() || {}

const {
    REACT_APP_ENV_NAME = 'st-dev-1',
    REACT_APP_ENV: NODE_ENV = 'production',
    REACT_APP_DEV = false,
    REACT_APP_LIVE_PERSON = false,
    REACT_APP_HOTJAR_ENABLED = true,

    REACT_APP_PUBLIC_URL = 'http://www.storagetreasures.com',
    REACT_APP_SITE_NAME = 'Storagetreasures.com',
    REACT_APP_COOKIE_DOMAIN = undefined,
    REACT_APP_AUTHNET_URL = undefined,

    REACT_APP_DATE_PRIVACY = '11/03/2022',
    REACT_APP_DATE_TERMS = '12/07/2023',

    REACT_APP_CLASSIC_SITE_URL = undefined,
    REACT_APP_ROBOTS_ALLOW = undefined,

    REACT_APP_COGNITO_REGION, //: region = false,
    REACT_APP_COGNITO_USER_POOL_ID, //: userPoolId = false,
    REACT_APP_COGNITO_APP_CLIENT_ID, //: userPoolWebClientId = false,

    REACT_APP_GOOGLE_TAG_MANAGER, // Google tag manager

    REACT_APP_COLOR, // color of the thingamabob open/close button
    REACT_APP_GOOGLE_ANALYTICS, // Google analytics

    REACT_APP_GOOGLE_SITE_KEY,
    // REACT_APP_GOOGLE_SECRET_KEY,

    REACT_APP_NEW_RELIC_LICENSE_KEY,
    REACT_APP_NEW_RELIC_LOADER_CONFIG,
    REACT_APP_REQUIRE_IDENTITY,
    REACT_APP_ENABLE_PRIVATE_SELLERS,
    REACT_APP_IDENTITY_SHOW_SSN,
    REACT_APP_UPDATE_MEMBERSHIP,
    REACT_APP_ADD_AUTHORIZED,
    REACT_APP_SUPPORT_PHONE_PRO = '',
    REACT_APP_INVOICE_AFP_DATE = '',
    REACT_APP_HERE_API_KEY,
    REACT_APP_MAINTENANCE_NOTICE,
    REACT_APP_MAINTENANCE_MODE,
    REACT_APP_ENABLE_LIEN_LAWS,
    REACT_APP_ENABLE_PUBLIC_NOTICE
} = publicRuntimeConfig || {}

export const APP_CONFIG = {
    DEV: REACT_APP_DEV == true || REACT_APP_DEV == '1' || REACT_APP_DEV == 'true',  // Basically enables the thingamabob
    APP_ENV: NODE_ENV,

    ROBOTS_ALLOW: REACT_APP_ROBOTS_ALLOW == true || REACT_APP_ROBOTS_ALLOW == '1' || REACT_APP_ROBOTS_ALLOW == 'true',
    APP_URL: REACT_APP_PUBLIC_URL, // || location.protocol + '//' + location.host + '/',
    AUTHNET_URL: REACT_APP_AUTHNET_URL,
    APP_SITE_NAME: REACT_APP_SITE_NAME,

    REACT_APP_ENV_NAME,
    REACT_APP_LIVE_PERSON,
    REACT_APP_HOTJAR_ENABLED,
    REACT_APP_PUBLIC_URL,
    REACT_APP_CLASSIC_SITE_URL,
    REACT_APP_NEW_RELIC_LICENSE_KEY,
    REACT_APP_NEW_RELIC_LOADER_CONFIG,
    REACT_APP_DATE_PRIVACY,
    REACT_APP_DATE_TERMS,
    REACT_APP_REQUIRE_IDENTITY: REACT_APP_REQUIRE_IDENTITY === 'true',
    REACT_APP_ENABLE_PRIVATE_SELLERS: REACT_APP_ENABLE_PRIVATE_SELLERS === 'true',
    REACT_APP_IDENTITY_SHOW_SSN: REACT_APP_IDENTITY_SHOW_SSN === 'true',
    REACT_APP_UPDATE_MEMBERSHIP: REACT_APP_UPDATE_MEMBERSHIP === 'true',
    REACT_APP_ADD_AUTHORIZED: REACT_APP_ADD_AUTHORIZED === 'true',
    REACT_APP_HERE_API_KEY,
    REACT_APP_MAINTENANCE_NOTICE,
    REACT_APP_MAINTENANCE_MODE: REACT_APP_MAINTENANCE_MODE === 'true',
    RELEASE_LIEN_LAWS: REACT_APP_ENABLE_LIEN_LAWS === 'true',
    RELEASE_PUBLIC_NOTICE: REACT_APP_ENABLE_PUBLIC_NOTICE === 'true',

    DEV_SETTINGS: {
        APP_COLOR: REACT_APP_COLOR
    },

    VALIDATION: {
        ZIPCODE: /(\d{5})|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/,
        API_FORMAT_PHONE: /\(\d{3}\)\s\d{3}-\d{4}/,
        // format of the phone number updated. saving this in case we go back.
        FORMAT_PHONE: /\(\d{3}\)\s\d{3}-\d{4}/,
        // from here - http://regexlib.com/REDetails.aspx?regexp_id=1721
        PASSWORD: /(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/,
        EMAIL: /^([\w-+]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
    },

    VALIDATION_MESSAGES: {
        PASSWORD: 'This field is required. Please check password requirements.'
    },

    // using react-input-mask link in the readme.md file
    MASK: {
        ZIPCODE: {
            237: '99999', // US
            37: 'a9a 9a9' // CAD
        },

        PHONE: '(999) 999-9999'
    },

    COOKIE_DOMAIN: REACT_APP_COOKIE_DOMAIN || REACT_APP_PUBLIC_URL,
    REACT_APP_SUPPORT_PHONE_PRO,
    REACT_APP_INVOICE_AFP_DATE,

    Auth: {
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        mandatorySignIn: false,
        region: REACT_APP_COGNITO_REGION,
        userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
        cookieStorage: {
            // required
            domain: REACT_APP_COOKIE_DOMAIN || REACT_APP_PUBLIC_URL,
            secure: true
        }
    },

    Services: {
        gtmId: REACT_APP_GOOGLE_TAG_MANAGER,
        gaId: REACT_APP_GOOGLE_ANALYTICS,

        google_recaptcha: {
            site_key: REACT_APP_GOOGLE_SITE_KEY
            // secret_key: REACT_APP_GOOGLE_SECRET_KEY,
        }
    },


    env: publicRuntimeConfig
}
