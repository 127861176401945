import Button from 'components/partials/Fields/Button'
import { APP_CONFIG } from 'Config'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'

const ManageInviteModal = ({
    show = false,
    invite = {},
    handleConfirm = () => {},
    handleReject = () => {},
    setShow = () => {}
}) => {
    const { REACT_APP_UPDATE_MEMBERSHIP } = APP_CONFIG

    const handleHide = () => {
        setShow(false)
    }

    return (
        <Modal
            name="manage-pickup-person-modal"
            show={show}
            className="add-pickup-person-modal"
            onHide={handleHide}
        >
            <ModalHeader closeButton>
                <ModalTitle>Invitation</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <p>
                    You have been invited to be an Authorized Pickup Person by {invite?.fname} {invite?.lname?.trim()[0]}.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button
                    label="Decline"
                    className="btn btn-blue btn-regular w-100 marginT20"
                    onClick={handleReject}
                />
                <Button
                    label="Accept"
                    className="btn btn-blue btn-regular w-100 marginT20"
                    onClick={handleConfirm}
                    disabled={!REACT_APP_UPDATE_MEMBERSHIP}
                />
            </ModalFooter>
        </Modal>
    )
}

export default ManageInviteModal
