import Button from 'components/partials/Fields/Button'
import Cancel from 'components/partials/Fields/Cancel'
import { Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'

const ConfirmationModal = ({
    show,
    title = 'Confirm Invite',
    text = 'Are you sure you want to send this Authorized Pickup Person invite?',
    submitLabel = 'Send',
    loading = false,
    handleConfirm = () => {},
    setShow = () => {},
    setEmail = () => {}
}) => {

    const handleHide = () => {
        setShow(false)
        setEmail('')
    }

    const handleCancelInvite = () => {
        setShow(false)
        setEmail('')
    }

    return (
        <Modal
            name="confirmation-modal"
            show={show}
            className="confirmation-modal"
            onHide={handleHide}
        >
            <ModalHeader closeButton>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                {text}
            </ModalBody>
            <ModalFooter className="flex-column">
                <Row className="mb-2">
                    <Button
                        label={submitLabel}
                        className="btn btn-blue px-5"
                        onClick={handleConfirm}
                        loading={loading}
                        disabled={loading}
                    />
                </Row>
                <Row className='text-center'>
                    <Cancel
                        okAction={handleCancelInvite}
                        label="Cancel"
                        id="cancel"
                        cancelConfirmation={false}
                    />
                </Row>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal
